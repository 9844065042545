import React, { CSSProperties, FC, useCallback, useEffect, useRef, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import {
  DictProps,
  DictStatesEnum,
  SettingsPopupProps,
  Framework,
  IModel,
  IDomain,
  ILanguage,
  IConfigPatch,
  ISttStatus,
  IModelMetadata,
} from './ISettings';
import {
  setActiveQuota,
  setConfig,
  setConsumptionModal,
  setDashboardFilters,
  setDashboardPagination,
  setRefetchTokens,
  setSttStatus,
  setUser,
} from '../../redux/actions/actions';
import { batch, useDispatch, useSelector } from 'react-redux';
import { IStore, UserRoleEnums } from '../../redux/store/IStore';
import {
  removeTokens,
} from '../../api/AuthenticationService';
import '../../styles/css/settingspopover.css';
import { AnimatePresence, motion } from 'framer-motion';
import {
  DictDesynchedIcon,
  DictEditIcon,
  DictSynchedIcon,
  DictSyncingIcon0,
  DictSyncingIcon1,
  DictSyncingIcon2,
  DictSyncingIcon3,
  FailedSyncingIcon,
} from '../Icons/RefreshDicIcons';
import useInterval from '../../hooks/useInterval';
import {
  getDictionaryWordsCount,
  getModelRegenerationStatus,
  startModelRegeneration,
} from '../../api/DictionaryService';
import useCheckIfModelAvailable from '../../hooks/useCheckIfModelAvailable';
import { getConfiguration, getSttStatus, setConfiguration } from '../../api/configurationService';
import AddLabelsButton from './AddLabelsButton';

import { ClearIcon } from '../Icons/ClearIcon';
import { useDebounce } from '../../hooks/useDebounce';
import { useHistory } from 'react-router-dom';
import { config } from '../../config';
import { useSnackbar } from 'notistack';
import './settings.css';
import { isAfter, isEqual, parse } from 'date-fns';
import ConsumptionBar from './ConsumptionBar';
import { transformConsumedAndLimitToReadable, transformConsumedToReadable, translateChunkKey } from '../../api/UserService';
import useKeycloak from '../../hooks/useKeycloak';
import { keycloak } from '../../providers/KeycloakAuthProvider';
import { config as envConfig } from '../../config'
import { isUpdating } from '../../shared/helpers';

const settingsVariants = {
  visible: {
    x: 0,
    transition: { ease: 'easeInOut', duration: 0.35 },
  },
  hidden: {
    x: -500,
    transition: { ease: 'easeInOut', duration: 0.35 },
  },
};

const versionToNumber = (version: string, checkIfFits?: boolean) => {
  const newValue = Number(version.replace('-', ''));
  if (isNaN(newValue)) {
    if (checkIfFits) return -1;
  }
  if (checkIfFits) return 1;

  return newValue;
};

const { 
  publicUrl,
  keycloakClientId,
  keycloakAuthReam,
  keycloakUrl
} = config;


const getAllAllowedModels = (sttStatus: ISttStatus) =>
  sttStatus.frameworks
    .filter((framework) => framework.isAllowed)
    .flatMap((framework) => framework.languages)
    .filter((language) => language.isAllowed)
    .flatMap((language) => language.domains)
    .filter((domain) => domain.isAllowed)
    .flatMap((domain) => domain.models)
    .filter((model) => model.isAllowed);

const getAllAllowedDomains = (sttStatus: ISttStatus) =>
  sttStatus.frameworks
    .filter((framework) => framework.isAllowed)
    .flatMap((framework) => framework.languages)
    .filter((language) => language.isAllowed)
    .flatMap((language) => language.domains)
    .filter((domain) => domain.isAllowed);

const getAllAllowedLanguages = (sttStatus: ISttStatus) =>
  sttStatus.frameworks
    .filter((framework) => framework.isAllowed)
    .flatMap((framework) => framework.languages)
    .filter((language) => language.isAllowed);

const getFilteredAllowedModels = (
  sttStatus: ISttStatus,
  frameworkValue: string,
  languageValue: string,
  domainValue: string
) =>
  sttStatus.frameworks
    .filter((framework) => framework.isAllowed && framework.code === frameworkValue)
    .flatMap((framework) => framework.languages)
    .filter((language) => language.isAllowed && language.code === languageValue)
    .flatMap((language) => language.domains)
    .filter((domain) => domain.isAllowed && domain.code === domainValue)
    .flatMap((domain) => domain.models)
    .filter((model) => model.isAllowed);

const getFilteredAllowedDomains = (sttStatus: ISttStatus, frameworkValue: string, languageValue: string) =>
  sttStatus.frameworks
    .filter((framework) => framework.isAllowed && framework.code === frameworkValue)
    .flatMap((framework) => framework.languages)
    .filter((language) => language.isAllowed && language.code === languageValue)
    .flatMap((language) => language.domains)
    .filter((domain) => domain.isAllowed);

const getFilteredAllowedLanguages = (sttStatus: ISttStatus, frameworkValue: string) =>
  sttStatus.frameworks
    .filter((framework) => framework.isAllowed && framework.code === frameworkValue)
    .flatMap((framework) => framework.languages)
    .filter((language) => language.isAllowed);

const getFilteredAllowedDomainsWithoutFramework = (sttStatus: ISttStatus, languageValue: string) =>
  sttStatus.frameworks
    .filter((framework) => framework.isAllowed)
    .flatMap((framework) => framework.languages)
    .filter((language) => language.isAllowed && language.code === languageValue)
    .flatMap((language) => language.domains)
    .filter((domain) => domain.isAllowed);

const getFilteredAllowedModelsWithoutFramework = (
  sttStatus: ISttStatus,
  languageValue: string,
  domainValue: string
) =>
  sttStatus.frameworks
    .filter((framework) => framework.isAllowed)
    .flatMap((framework) => framework.languages)
    .filter((language) => language.isAllowed && language.code === languageValue)
    .flatMap((language) => language.domains)
    .filter((domain) => domain.isAllowed && domain.code === domainValue)
    .flatMap((domain) => domain.models)
    .filter((model) => model.isAllowed);

const makeUniqueObjectArrayFromGivenKey = (array: any[], key: string) => {
  const filteredArray: any[] = [];
  array.forEach((element) => {
    const isAlreadyIncluded = filteredArray.some((filteredElement) => filteredElement[key] === element[key]);
    if (!isAlreadyIncluded) filteredArray.push(element);
  });
  return filteredArray;
};

const NUMBER_OF_CONFIG_RETRY = 3
const SettingsDrawer: React.FC<SettingsPopupProps> = ({
  handleCloseSettings,
  show,
  fileUploadIsInProgress,
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const user = useSelector((state: IStore) => state.user);
  const [loadingCfg, setLoadingCfg] = useState(true);
  const [configError, setConfigError] = useState(false);
  const config = useSelector((state: IStore) => state.config);
  const sttStatus = useSelector((state: IStore) => state.sttStatus);
  const activeQuota = useSelector((state: IStore) => state.activeQuota);
  const isAdmin = user ? user.userRoles.includes(UserRoleEnums.ADMIN) : false
  const debouncedCfg = useDebounce(config, 1000, []);
  const { isAvailable: currentModelAvailable } = useCheckIfModelAvailable();
  const history = useHistory();

  const [authFailed, setAuthFailed] = useState<boolean | string>(false);
  
  const [password, setPassword] = useState<string>('');
  const [allowModelUpdating, setAllowModelUpdating] = useState<boolean>(true);
  const [modelMetadata, setModelMetadata] = useState<IModelMetadata | undefined>(undefined);
  const [modelInfoModalVisible, setModelInfoVisible] = useState<boolean>(false); //null when info is not available, undefined when modal should be hidden

  const changedCfg = useRef<IConfigPatch>({});
  const settingsRef = useRef(null);
  const dictationCommandsSupport = useRef<boolean>(false);
  const diarizationSupport = useRef<boolean>(false);
  const isModelUpdating = useRef<boolean>(false);
  const isModelUpdatable = useRef<boolean>(false);

  const [localLanguages, setLocalLanguages] = useState<ILanguage[] | null>(null);
  const [localDomains, setLocalDomains] = useState<IDomain[] | null>(null);
  const [localModels, setLocalModels] = useState<IModel[] | null>(null);

  const {
    isAuthenticated
  } = useKeycloak();

  const onRegenStatusCallback = useCallback(
    (status) => {
      setAllowModelUpdating(status ? status : false);
    },
    [setAllowModelUpdating]
  );

  const resetState = () => {
    setAuthFailed(false);
    setPassword('');
  };

  const counterRef = useRef<number>(0);

  const refreshConfiguration = async () => {
    if (!user || !user.accessToken || !user.isEditorUser) return;
    
    setConfigError(false)
    try {
      const newSttStatus = (await getSttStatus()).data;
      const newConfig = (await getConfiguration()).data;


      newSttStatus.frameworks.forEach(framework => {
        if (framework.code === newConfig.stt.framework.value) {
          framework.languages.forEach(language => {
            if (language.code === newConfig.stt.language.value) {
              language.domains.forEach(domain => {
                if (domain.code === newConfig.stt.domain.value) {
                  domain.models.forEach(model => {
                    if (model.code === newConfig.stt.model.value) {
                      isModelUpdatable.current = model.isUpdatable;
                      isModelUpdating.current = model.isUpdating;
                      dictationCommandsSupport.current =  model.dictationCommandsSupport;
                      diarizationSupport.current = model.diarizationSupport;
                    }
                  })
                }
              })
            }
          })
        }
      })


      

      if (!newConfig.stt?.framework?.value) {
        // No framework is selected. There are two use cases:
        //
        //  1. new users, that have no chosen model
        //  2. existing users, that have a model, but framework is <null> (non-admins)
        //
        // For the new users, we preselect newest available model. For existing users that
        // are not admins, we select the framework based on chosen model.
        let selectedFramework = '';
        let selectedLanguage = '';
        let selectedDomain = '';
        let selectedModel = '';
  
        if (
          !!newConfig.stt?.model?.value &&
          !!newConfig.stt?.domain?.value &&
          !!newConfig.stt?.language?.value
        ) {
          // User has selected model, but no framework. So we preselect the framework based on model/domain/language.
          newSttStatus.frameworks.every((framework) =>
            framework.languages.every((language) =>
              language.domains.every((domain) =>
                domain.models.every((model) => {
                  if (
                    model.code === newConfig.stt.model.value &&
                    domain.code === newConfig.stt.domain.value &&
                    language.code === newConfig.stt.language.value
                  ) {
                    selectedFramework = framework.code;
                    return false;
                  }
  
                  return true;
                })
              )
            )
          );
  
          if (!!selectedFramework) {
            newConfig.stt.framework.value = selectedFramework as Framework;
          }
        } else {
          // framework/language/domain/model aren't set (new user?), so we preselect the newest model
          let modelTs = new Date(0);
  
          for (const framework of newSttStatus.frameworks.filter((f) => f.isAllowed)) {
            for (const language of framework.languages.filter((l) => l.isAllowed)) {
              for (const domain of language.domains.filter((d) => d.isAllowed)) {
                for (const model of domain.models.filter((m) => m.isAllowed)) {
                  // expecting version string as timestamp 'yyyyMMdd-HHmm', e.g. '20221017-1539'
                  const version = model.code?.substring(0, 13);
                  try {
                    const ts = parse(version, 'yyyyMMdd-HHmm', new Date());
                    const isNewerTimestamp = isAfter(ts, modelTs);
                    // if timestamps are equal, then we string compare both models:
                    //   the one that has any additional characters is considered newer,
                    //   e.g. '20210923-2017_6s' > '20210923-2017'
                    const isNewerVersion = isEqual(ts, modelTs) && selectedModel < model.code;
  
                    if (isNewerTimestamp || isNewerVersion) {
                      modelTs = ts;
                      selectedModel = model.code;
                      selectedDomain = domain.code;
                      selectedLanguage = language.code;
                      selectedFramework = framework.code;
                    }
                  } catch {
                    // could not parse the timestamp from model.code
                    if (!selectedModel) {
                      // We make sure at least something is selected, in case none of the models parsed as timestamp
                      selectedModel = model.code;
                      selectedDomain = domain.code;
                      selectedLanguage = language.code;
                      selectedFramework = framework.code;
                    }
                  }
                }
              }
            }
          }
  
          newConfig.stt.framework.value = selectedFramework as Framework;
          newConfig.stt.language.value = selectedLanguage;
          newConfig.stt.domain.value = selectedDomain;
          newConfig.stt.model.value = selectedModel;
        }
  
        changedCfg.current = {
          ...changedCfg.current,
          stt: {
            ...changedCfg.current.stt,
            framework: {
              value: newConfig.stt.framework.value,
            },
            language: {
              value: newConfig.stt.language.value,
            },
            domain: {
              value: newConfig.stt.domain.value,
            },
            model: {
              value: newConfig.stt.model.value,
            },
          },
        };
      }
  
      if (newConfig.stt.framework.value && isAdmin) {
        setLocalLanguages(
          makeUniqueObjectArrayFromGivenKey(
            getFilteredAllowedLanguages(newSttStatus, newConfig.stt.framework.value),
            'code'
          )
        );
      } else {
        setLocalLanguages(makeUniqueObjectArrayFromGivenKey(getAllAllowedLanguages(newSttStatus), 'code'));
      }
  
      if (newConfig.stt.framework.value && newConfig.stt.language.value && isAdmin) {
        setLocalDomains(
          makeUniqueObjectArrayFromGivenKey(
            getFilteredAllowedDomains(
              newSttStatus,
              newConfig.stt.framework.value,
              newConfig.stt.language.value
            ),
            'code'
          )
        );
      } else if (newConfig.stt.language.value) {
        setLocalDomains(
          makeUniqueObjectArrayFromGivenKey(
            getFilteredAllowedDomainsWithoutFramework(newSttStatus, newConfig.stt.language.value),
            'code'
          )
        );
      } else {
        setLocalDomains(makeUniqueObjectArrayFromGivenKey(getAllAllowedDomains(newSttStatus), 'code'));
      }
  
      if (
        newConfig.stt.framework.value &&
        newConfig.stt.language.value &&
        newConfig.stt.domain.value &&
        isAdmin
      ) {
        const filteredModels = getFilteredAllowedModels(
          newSttStatus,
          newConfig.stt.framework.value,
          newConfig.stt.language.value,
          newConfig.stt.domain.value
        )

        /*console.log("filteredModels")
        console.log(filteredModels)
        console.log(`${newConfig.stt.framework.value} | ${newConfig.stt.language.value} | ${newConfig.stt.domain.value} | ${newConfig.stt.model.value}`)*/
        setLocalModels(
          makeUniqueObjectArrayFromGivenKey(
            filteredModels,
            'code'
          )
        );
      } else if (newConfig.stt.language.value && newConfig.stt.domain.value) {
        const filteredModels = getFilteredAllowedModelsWithoutFramework(
          newSttStatus,
          newConfig.stt.language.value,
          newConfig.stt.domain.value
        )

        /*console.log("allowed filteredModels without framework")
        console.log(filteredModels)
        console.log(`${newConfig.stt.framework.value} | ${newConfig.stt.language.value} | ${newConfig.stt.domain.value} | ${newConfig.stt.model.value}`)*/
        setLocalModels(
          makeUniqueObjectArrayFromGivenKey(
            filteredModels,
            'code'
          )
        );
      } else {
        const filteredModels = getAllAllowedModels(newSttStatus)

        /*console.log("all allowed filteredModels")
        console.log(filteredModels)
        console.log(`${newConfig.stt.framework.value} | ${newConfig.stt.language.value} | ${newConfig.stt.domain.value} | ${newConfig.stt.model.value}`)*/
        setLocalModels(makeUniqueObjectArrayFromGivenKey(filteredModels, 'code'));
      }
  
      dispatch(setConfig(newConfig));
      dispatch(setSttStatus(newSttStatus));
      /*const test: IActiveUserQuotaChunk = {
        key: UserQuotaKeys.monthly,
        chunk: {
          secondsConsumed: 50,
          secondsLimit: 51,
          secondsWarn: 30
        }
      } */
      //dispatch(setActiveQuota(test))
      counterRef.current = 0;
    } catch (error) {
      setConfigError(true)
      const mappedError = error as any
      if (mappedError && mappedError.response && mappedError.response.status && mappedError.response.status === 500) {
        counterRef.current += 1;

        if (counterRef.current === NUMBER_OF_CONFIG_RETRY) {
          counterRef.current = 0;
          handleLogoutClick(true);
          return;
        }
      }
    }
    setLoadingCfg(false);
  };

  //this effect updates status
  useEffect(() => {
    if (!user || !isAuthenticated) return;

    refreshConfiguration();
  }, [user?.isAuthenticated, user?.username]);

  useEffect(() => {
    refreshConfiguration();
  }, [history]);

  useInterval(
    () => {
      if (user && isAuthenticated && user.username) {
        refreshConfiguration();
      }
    },
    10000,
    [config, user?.isAuthenticated, user?.username]
  );

  const handleLogoutClick = useCallback((clearRedux: boolean) => {
    //This if was added because actions are dispatched in axios response interceptor
    if (clearRedux) {
      batch(() => {
        dispatch(setRefetchTokens(null));
        dispatch(setUser(null));


        dispatch(setConfig(null));
        dispatch(setSttStatus(null));
        dispatch(setDashboardFilters([]));
        dispatch(setActiveQuota(null))
      });

      removeTokens();
    }

    
    resetState();
    setLocalLanguages(null);
    setLocalDomains(null);
    setLocalModels(null);
    
    localStorage.clear();
    keycloak.clearToken()
    if (user && user.idToken) {
      keycloak.createLogoutUrl = () => `${keycloakUrl}/auth/realms/${keycloakAuthReam}/protocol/openid-connect/logout?id_token_hint=${user.idToken}&post_logout_redirect_uri=${publicUrl}&client_id=${keycloakClientId}&initiating_idp=${envConfig.keycloakIdpAlias}`
    }
    keycloak.logout()
  }, [user]);

  const closeModelInfoModal = () => {
    setModelMetadata(undefined);
    setModelInfoVisible(false);
  };

  const preventOnClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  const openModelInfoModal = (modelInfo: IModelMetadata | undefined) => {
    setModelMetadata(modelInfo);
    setModelInfoVisible(true);
  };

  const onModelInfoClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (!sttStatus || !config) return false;

    const framework = sttStatus.frameworks.find((framework) => framework.code === config.stt.framework.value);
    if (!framework) return false;

    const language = framework.languages.find((language) => language.code === config.stt.language.value);
    if (!language) return false;

    const domain = language.domains.find((domain) => domain.code === config.stt.domain.value);
    if (!domain) return false;

    const model = domain.models.find((model) => model.code === config.stt.model.value);
    if (!model) return false;

    openModelInfoModal(model.metadata);
  };

  const showConsumptionModal = () => dispatch(setConsumptionModal({
    visible: true,
    template: undefined,
  }))

  const handleFrameworkChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!sttStatus || !config) return;

    const configCopy = { ...config };

    const newFrameworkValue = event.target.value;

    const languages = getFilteredAllowedLanguages(sttStatus, newFrameworkValue);
    if (languages.length <= 0) {
      enqueueSnackbar(`Na voljo ni veljavnih jezikov za izbrano ogrodje ${newFrameworkValue}.`, {
        variant: 'error',
      });
      return;
    }
    const language = languages[0];

    const domains = getFilteredAllowedDomains(sttStatus, newFrameworkValue, language.code);
    if (domains.length <= 0) {
      enqueueSnackbar(`Na voljo ni veljavnih domen za izbrani jezik ${language.code}.`, {
        variant: 'error',
      });
      return;
    }
    const domain = domains[0];

    const models = getFilteredAllowedModels(sttStatus, newFrameworkValue, language.code, domain.code);
    if (domains.length <= 0) {
      enqueueSnackbar(`Na voljo ni veljavnih modelov za izbrano domeno ${domain.code}.`, {
        variant: 'error',
      });
      return;
    }
    const model = models[0];

    configCopy.stt.framework.value = newFrameworkValue as Framework;
    configCopy.stt.language.value = language.code;
    configCopy.stt.domain.value = domain.code;
    configCopy.stt.model.value = model.code;

    dictationCommandsSupport.current = model.dictationCommandsSupport;
    diarizationSupport.current = model.diarizationSupport;
    isModelUpdating.current = model.isUpdating;
    isModelUpdatable.current = model.isUpdatable;

    const framework = sttStatus.frameworks.find(framework => framework.code === configCopy.stt.framework.value)
    if (framework && framework.isAllowed) {
      const language = framework.languages.find(language => language.code === configCopy.stt.language.value)
      if (language && language.isAllowed) {
        const domain = language.domains.find(domain => domain.code === configCopy.stt.domain.value)
        if (domain && domain.isAllowed) {
          const model = domain.models.find(model => model.code === configCopy.stt.model.value)
          if (model && model.isAllowed) {
            if (!model.isRealtime && configCopy.stt.enableInterimTranscripts) {
              //TODO: Update configuraton to disable enableInterimTranscripts
              try {
                await setConfiguration({stt: {enableInterimTranscripts: {value: false}}})
                configCopy.stt.enableInterimTranscripts.value = false
              } catch (error) {
                console.log(error)
              }
            }
          }
        }
      }
    }

    changedCfg.current = {
      ...changedCfg.current,
      stt: {
        ...changedCfg.current.stt,
        framework: {
          value: newFrameworkValue as Framework,
        },
        language: {
          value: language.code,
        },
        domain: {
          value: domain.code,
        },
        model: {
          value: model.code,
        },
        enableDiarization: {
          isAllowed: model.diarizationSupport,
          value: model.diarizationSupport ? configCopy.stt.enableDiarization.value : false,
        },
        enableDictatedPunctuations: {
          isAllowed: model.dictationCommandsSupport,
          value: model.dictationCommandsSupport ? configCopy.stt.enableDictatedPunctuations.value : false,
        },
        enableDictatedCommands: {
          isAllowed: model.dictationCommandsSupport,
          value: model.dictationCommandsSupport ? configCopy.stt.enableDictatedCommands.value : false,
        },
      },
    };

    setLocalLanguages(languages);
    setLocalDomains(domains);
    setLocalModels(models);
    dispatch(setConfig(configCopy));
  };

  const handleLanguageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!sttStatus || !config) return;

    const configCopy = { ...config };

    const newLanguageValue = event.target.value;
    const currentFrameworkValue = configCopy.stt.framework.value;

    const domains =
      currentFrameworkValue && isAdmin
        ? makeUniqueObjectArrayFromGivenKey(
            getFilteredAllowedDomains(sttStatus, currentFrameworkValue, newLanguageValue),
            'code'
          )
        : makeUniqueObjectArrayFromGivenKey(
            getFilteredAllowedDomainsWithoutFramework(sttStatus, newLanguageValue),
            'code'
          );

    if (domains.length <= 0) {
      enqueueSnackbar(`Na voljo ni veljavnih domen za izbrani jezik ${newLanguageValue}.`, {
        variant: 'error',
      });
      return;
    }
    const domain = domains[0];

    const models =
      currentFrameworkValue && isAdmin
        ? makeUniqueObjectArrayFromGivenKey(
            getFilteredAllowedModels(sttStatus, currentFrameworkValue, newLanguageValue, domain.code),
            'code'
          )
        : makeUniqueObjectArrayFromGivenKey(
            getFilteredAllowedModelsWithoutFramework(sttStatus, newLanguageValue, domain.code),
            'code'
          );

    if (models.length <= 0) {
      enqueueSnackbar(`Na voljo ni veljavnih modelov za izbrano domeno ${domain.code}.`, {
        variant: 'error',
      });
      return;
    }
    const model = models[0];

    configCopy.stt.model.value = model.code;
    if (isAdmin) {
      configCopy.stt.language.value = newLanguageValue;
      configCopy.stt.domain.value = domain.code;
    } else {
      // change framework as well according to chosen model
      for (const framework of sttStatus.frameworks.filter((f) => f.isAllowed)) {
        for (const language of framework.languages.filter((l) => l.isAllowed)) {
          for (const domain of language.domains.filter((d) => d.isAllowed)) {
            for (const model of domain.models.filter((m) => m.isAllowed)) {
              if (model.code === configCopy.stt.model.value) {
                configCopy.stt.language.value = language.code;
                configCopy.stt.domain.value = domain.code;
                configCopy.stt.framework.value = framework.code as Framework;
              }
            }
          }
        }
      }
    }

    const framework = sttStatus.frameworks.find(framework => framework.code === configCopy.stt.framework.value)
    if (framework && framework.isAllowed) {
      const language = framework.languages.find(language => language.code === configCopy.stt.language.value)
      if (language && language.isAllowed) {
        const domain = language.domains.find(domain => domain.code === configCopy.stt.domain.value)
        if (domain && domain.isAllowed) {
          const model = domain.models.find(model => model.code === configCopy.stt.model.value)
          if (model && model.isAllowed) {
            if (!model.isRealtime && configCopy.stt.enableInterimTranscripts) {
              //TODO: Update configuraton to disable enableInterimTranscripts
              try {
                await setConfiguration({stt: {enableInterimTranscripts: {value: false}}})
                configCopy.stt.enableInterimTranscripts.value = false
              } catch (error) {
                console.log(error)
              }
            }
          }
        }
      }
    }

    dictationCommandsSupport.current = model.dictationCommandsSupport;
    diarizationSupport.current = model.diarizationSupport;
    isModelUpdating.current = model.isUpdating;
    isModelUpdatable.current = model.isUpdatable;

    changedCfg.current = {
      ...changedCfg.current,
      stt: {
        ...changedCfg.current.stt,
        model: {
          value: configCopy.stt.model.value,
        },
        framework: {
          value: configCopy.stt.framework.value,
        },
        language: {
          value: configCopy.stt.language.value,
        },
        domain: {
          value: configCopy.stt.domain.value,
        },
        enableDiarization: {
          isAllowed: model.diarizationSupport,
          value: model.diarizationSupport ? configCopy.stt.enableDiarization.value : false,
        },
        enableDictatedPunctuations: {
          isAllowed: model.dictationCommandsSupport,
          value: model.dictationCommandsSupport ? configCopy.stt.enableDictatedPunctuations.value : false,
        },
        enableDictatedCommands: {
          isAllowed: model.dictationCommandsSupport,
          value: model.dictationCommandsSupport ? configCopy.stt.enableDictatedCommands.value : false,
        },
      },
    };

    setLocalDomains(domains);
    setLocalModels(models);
    dispatch(setConfig(configCopy));
  };

  const handleDomainChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!sttStatus || !config) return;

    const newDomainValue = event.target.value;

    if (config.stt.language.value === '') {
      enqueueSnackbar(
        `Izbrali ste domeno ${newDomainValue} brez izbranega jezika. To je neveljavna konfiguracija`,
        {
          variant: 'error',
        }
      );
      return;
    }

    const configCopy = { ...config };
    const currentFrameworkValue = configCopy.stt.framework.value;
    const currentLanguageValue = configCopy.stt.language.value;

    const models =
      currentFrameworkValue && isAdmin
        ? makeUniqueObjectArrayFromGivenKey(
            getFilteredAllowedModels(sttStatus, currentFrameworkValue, currentLanguageValue, newDomainValue),
            'code'
          )
        : makeUniqueObjectArrayFromGivenKey(
            getFilteredAllowedModelsWithoutFramework(sttStatus, currentLanguageValue, newDomainValue),
            'code'
          );

    if (models.length <= 0) {
      enqueueSnackbar(`Na voljo ni veljavnih modelov za izbrano domeno ${newDomainValue}.`, {
        variant: 'error',
      });
      return;
    }
    const model = models[0];

    configCopy.stt.model.value = model.code;
    if (isAdmin) {
      configCopy.stt.domain.value = newDomainValue;
    } else {
      // change framework as well according to chosen model
      for (const framework of sttStatus.frameworks.filter((f) => f.isAllowed)) {
        for (const language of framework.languages.filter((l) => l.isAllowed)) {
          for (const domain of language.domains.filter((d) => d.isAllowed)) {
            for (const model of domain.models.filter((m) => m.isAllowed)) {
              if (model.code === configCopy.stt.model.value) {
                configCopy.stt.language.value = language.code;
                configCopy.stt.domain.value = domain.code;
                configCopy.stt.framework.value = framework.code as Framework;
              }
            }
          }
        }
      }
    }

    const framework = sttStatus.frameworks.find(framework => framework.code === configCopy.stt.framework.value)
    if (framework && framework.isAllowed) {
      const language = framework.languages.find(language => language.code === configCopy.stt.language.value)
      if (language && language.isAllowed) {
        const domain = language.domains.find(domain => domain.code === configCopy.stt.domain.value)
        if (domain && domain.isAllowed) {
          const model = domain.models.find(model => model.code === configCopy.stt.model.value)
          if (model && model.isAllowed) {
            if (!model.isRealtime && configCopy.stt.enableInterimTranscripts) {
              //TODO: Update configuraton to disable enableInterimTranscripts
              try {
                await setConfiguration({stt: {enableInterimTranscripts: {value: false}}})
                configCopy.stt.enableInterimTranscripts.value = false
              } catch (error) {
                console.log(error)
              }
            }
          }
        }
      }
    }

    dictationCommandsSupport.current = model.dictationCommandsSupport;
    diarizationSupport.current = model.diarizationSupport;
    isModelUpdating.current = model.isUpdating;
    isModelUpdatable.current = model.isUpdatable;

    changedCfg.current = {
      ...changedCfg.current,
      stt: {
        ...changedCfg.current.stt,
        model: {
          value: configCopy.stt.model.value,
        },
        framework: {
          value: configCopy.stt.framework.value,
        },
        language: {
          value: configCopy.stt.language.value,
        },
        domain: {
          value: configCopy.stt.domain.value,
        },
        enableDiarization: {
          isAllowed: model.diarizationSupport,
          value: model.diarizationSupport ? configCopy.stt.enableDiarization.value : false,
        },
        enableDictatedPunctuations: {
          isAllowed: model.dictationCommandsSupport,
          value: model.dictationCommandsSupport ? configCopy.stt.enableDictatedPunctuations.value : false,
        },
        enableDictatedCommands: {
          isAllowed: model.dictationCommandsSupport,
          value: model.dictationCommandsSupport ? configCopy.stt.enableDictatedCommands.value : false,
        },
      },
    };

    setLocalModels(models);
    dispatch(setConfig(configCopy));
  };

  const handleModelChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!sttStatus || !config) return;

    const newModelValue = event.target.value;

    if (config.stt.language.value === '') {
      enqueueSnackbar(
        `Izbrali ste model ${newModelValue} brez izbranega jezika. To je neveljavna konfiguracija`,
        {
          variant: 'error',
        }
      );
      return;
    }

    if (config.stt.domain.value === '') {
      enqueueSnackbar(
        `Izbrali ste model ${newModelValue} brez izbrane domene. To je neveljavna konfiguracija`,
        {
          variant: 'error',
        }
      );
      return;
    }

    const configCopy = { ...config };
    const currentFrameworkValue = configCopy.stt.framework.value;
    const currentLanguageValue = configCopy.stt.language.value;
    const currentDomainValue = configCopy.stt.domain.value;

    const models =
      currentFrameworkValue && isAdmin
        ? makeUniqueObjectArrayFromGivenKey(
            getFilteredAllowedModels(
              sttStatus,
              currentFrameworkValue,
              currentLanguageValue,
              currentDomainValue
            ),
            'code'
          )
        : makeUniqueObjectArrayFromGivenKey(
            getFilteredAllowedModelsWithoutFramework(sttStatus, currentLanguageValue, currentDomainValue),
            'code'
          );

    const model = models.find((model) => model.code === newModelValue);
    configCopy.stt.model.value = model.code;
    if (!isAdmin) {
      // change framework as well according to chosen model
      for (const framework of sttStatus.frameworks.filter((f) => f.isAllowed)) {
        for (const language of framework.languages.filter((l) => l.isAllowed)) {
          for (const domain of language.domains.filter((d) => d.isAllowed)) {
            for (const model of domain.models.filter((m) => m.isAllowed)) {
              if (model.code === configCopy.stt.model.value) {
                configCopy.stt.language.value = language.code;
                configCopy.stt.domain.value = domain.code;
                configCopy.stt.framework.value = framework.code as Framework;
              }
            }
          }
        }
      }
    }

    dictationCommandsSupport.current = model.dictationCommandsSupport;
    diarizationSupport.current = model.diarizationSupport;
    isModelUpdating.current = model.isUpdating;
    isModelUpdatable.current = model.isUpdatable;

    const framework = sttStatus.frameworks.find(framework => framework.code === configCopy.stt.framework.value)
    if (framework && framework.isAllowed) {
      const language = framework.languages.find(language => language.code === configCopy.stt.language.value)
      if (language && language.isAllowed) {
        const domain = language.domains.find(domain => domain.code === configCopy.stt.domain.value)
        if (domain && domain.isAllowed) {
          const model = domain.models.find(model => model.code === newModelValue)
          if (model && model.isAllowed) {
            if (!model.isRealtime && configCopy.stt.enableInterimTranscripts) {
              //TODO: Update configuraton to disable enableInterimTranscripts
              try {
                await setConfiguration({stt: {enableInterimTranscripts: {value: false}}})
                configCopy.stt.enableInterimTranscripts.value = false
              } catch (error) {
                console.log(error)
              }
            }
          }
        }
      }
    }
    
    changedCfg.current = {
      ...changedCfg.current,
      stt: {
        ...changedCfg.current.stt,
        model: {
          value: configCopy.stt.model.value,
        },
        framework: {
          value: configCopy.stt.framework.value,
        },
        language: {
          value: configCopy.stt.language.value,
        },
        domain: {
          value: configCopy.stt.domain.value,
        },
        enableDiarization: {
          isAllowed: model.diarizationSupport,
          value: model.diarizationSupport ? configCopy.stt.enableDiarization.value : false,
        },
        enableDictatedPunctuations: {
          isAllowed: model.dictationCommandsSupport,
          value: model.dictationCommandsSupport ? configCopy.stt.enableDictatedPunctuations.value : false,
        },
        enableDictatedCommands: {
          isAllowed: model.dictationCommandsSupport,
          value: model.dictationCommandsSupport ? configCopy.stt.enableDictatedCommands.value : false,
        },
      },
    };

    dispatch(setConfig(configCopy));
  };

  const handleDenormalizationToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!sttStatus || !config) return;

    const newDenormalisationValue = event.target.checked;

    if (newDenormalisationValue === config.nlp.enableDenormalization.value) return;

    const configCopy = { ...config };

    configCopy.nlp.enableDenormalization.value = newDenormalisationValue;
    changedCfg.current = {
      ...changedCfg.current,
      nlp: {
        ...changedCfg.current.nlp,
        enableDenormalization: {
          value: newDenormalisationValue,
        },
      },
    };

    dispatch(setConfig(configCopy));
  };

  const handleTruecasingToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!sttStatus || !config) return;

    const newTruecasingValue = event.target.checked;

    if (newTruecasingValue === config.nlp.enableTruecasing.value) return;

    const configCopy = { ...config };

    configCopy.nlp.enableTruecasing.value = newTruecasingValue;
    changedCfg.current = {
      ...changedCfg.current,
      nlp: {
        ...changedCfg.current.nlp,
        enableTruecasing: {
          value: newTruecasingValue,
        },
      },
    };

    dispatch(setConfig(configCopy));
  };

  const handlePunctuationToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!sttStatus || !config) return;

    const newPunctuationValue = event.target.checked;

    if (newPunctuationValue === config.nlp.punctuation.enabled.value) return;

    const configCopy = { ...config };

    configCopy.nlp.punctuation.enabled.value = newPunctuationValue;
    changedCfg.current = {
      ...changedCfg.current,
      nlp: {
        ...changedCfg.current.nlp,
        punctuation: {
          ...changedCfg.current.nlp?.punctuation,
          enabled: {
            ...changedCfg.current.nlp?.punctuation?.enabled,
            value: newPunctuationValue,
          },
        },
      },
    };

    dispatch(setConfig(configCopy));
  };

  const handleDiarizationToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!sttStatus || !config) return;

    const newDiarizationValue = event.target.checked;

    if (newDiarizationValue === config.stt.enableDiarization.value) return;

    const configCopy = { ...config };

    configCopy.stt.enableDiarization.value = newDiarizationValue;
    if (configCopy.stt.enableDiarizationSpeakerIdentification.isAllowed) {
      configCopy.stt.enableDiarizationSpeakerIdentification.value = newDiarizationValue;
      changedCfg.current = {
        ...changedCfg.current,
        stt: {
          ...changedCfg.current.stt,
          enableDiarization: {
            value: newDiarizationValue,
          },
          enableDiarizationSpeakerIdentification: {
            value: newDiarizationValue
          }
        },
      };
    } else {
      changedCfg.current = {
        ...changedCfg.current,
        stt: {
          ...changedCfg.current.stt,
          enableDiarization: {
            value: newDiarizationValue,
          },
        },
      };
    }

    dispatch(setConfig(configCopy));
  };

  const handleDictatedCommandsToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!sttStatus || !config) return;

    const newDictatedCommandsValue = event.target.checked;

    if (newDictatedCommandsValue === config.stt.enableDictatedCommands.value) return;

    const configCopy = { ...config };

    configCopy.stt.enableDictatedCommands.value = newDictatedCommandsValue;
    changedCfg.current = {
      ...changedCfg.current,
      stt: {
        ...changedCfg.current.stt,
        enableDictatedCommands: {
          value: newDictatedCommandsValue,
        },
      },
    };

    dispatch(setConfig(configCopy));
  };

  const handleDictatedPonctuationsToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!sttStatus || !config) return;

    const newDictatedPonctuationsValue = event.target.checked;

    if (newDictatedPonctuationsValue === config.stt.enableDictatedPunctuations.value) return;

    const configCopy = { ...config };

    configCopy.stt.enableDictatedPunctuations.value = newDictatedPonctuationsValue;
    changedCfg.current = {
      ...changedCfg.current,
      stt: {
        ...changedCfg.current.stt,
        enableDictatedPunctuations: {
          value: newDictatedPonctuationsValue,
        },
      },
    };

    dispatch(setConfig(configCopy));
  };

  const handleInterimsToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!sttStatus || !config) return;

    const newInterimsValue = event.target.checked;

    if (newInterimsValue === config.stt.enableInterimTranscripts.value) return;

    const configCopy = { ...config };

    configCopy.stt.enableInterimTranscripts.value = newInterimsValue;
    changedCfg.current = {
      ...changedCfg.current,
      stt: {
        ...changedCfg.current.stt,
        enableInterimTranscripts: {
          value: newInterimsValue,
        },
      },
    };

    dispatch(setConfig(configCopy));
  };

  const handleRealFinalsToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!sttStatus || !config) return;

    const newRealFinalsValue = event.target.checked;

    if (newRealFinalsValue === config.nlp.punctuation.enableRealFinals.value) return;

    const configCopy = { ...config };

    configCopy.nlp.punctuation.enableRealFinals.value = newRealFinalsValue;

    changedCfg.current = {
      ...changedCfg.current,
      nlp: {
        ...changedCfg.current.nlp,
        punctuation: {
          ...changedCfg.current.nlp?.punctuation,
          enableRealFinals: {
            ...changedCfg.current.nlp?.punctuation?.enableRealFinals,
            value: newRealFinalsValue,
          },
        },
      },
    };

    dispatch(setConfig(configCopy));
  };

  //this effect executes actual backend patch when debounce timeous out
  useEffect(() => {
    if (!debouncedCfg) return;
    if (!changedCfg.current.nlp && !changedCfg.current.stt) return;

    setConfiguration(changedCfg.current)
      .then((r) => {
        changedCfg.current = {};
      })
      .catch((e) => {
        changedCfg.current = {};
      });
  }, [debouncedCfg]);


  let isRealTime = false
  
  const framework = sttStatus && sttStatus.frameworks.find(framework => framework.code === config?.stt.framework.value)
  if (framework) {
    const language = framework.languages.find(language => language.code === config?.stt.language.value)
    if (language) {
      const domain = language.domains.find(domain => domain.code === config?.stt.domain.value)
      if (domain) {
        const model = domain.models.find(model => model.code === config?.stt.model.value)
        if (model) {
          isRealTime = model.isRealtime
        }
      }
    }
  }

  useEffect(() => {
    if(loadingCfg) return;

    const element = document.getElementById("mui-component-select-transcriptionModelVersion")
    if (!element) return;

    if (isModelUpdating.current) {
      element.classList.add("model-updating");
    } else {
      element.classList.remove("model-updating");
    }
    
  }, [loadingCfg, config])


  const [settingsVisible, setSettingsVisible] = useState<boolean>(show);

  useEffect(() => {
    if (show) {
      setSettingsVisible(show)
    } else {
      const timeout = setTimeout(() => setSettingsVisible(show), 200)
      return () => clearTimeout(timeout)
    }
  }, [show])

  return (
    <>
      <div>
        {settingsVisible && (
          <div style={{zIndex: isUpdating(sttStatus, config) ? 1001 : 30}}>
            <div className={`modal_wrapper ${!show || isUpdating(sttStatus, config) ? "modal_wrapper_without_blur" : "modal_wrapper_with_blur"}`} onClick={handleCloseSettings} />
            <div
              ref={settingsRef}
              className={`settings_form ${show ? 'settings-visible' : 'settings-hidden'}`}
            >
              <button
                type="button"
                style={{
                  // color: '#707070',
                  display: 'inline-flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  top: 25,
                  right: 25,
                }}
                onClick={() => {
                  config && setConfiguration(changedCfg.current);
                  handleCloseSettings();
                }}
              >
                <ClearIcon />
              </button>
              <div>
                <div className="tittle_style">Nastavitve</div>
                <div className="credentials_wrapper">
                  <div className="credentials_title_line">
                    <div>Avtentikacija</div>
                  </div>
                  <div className="credentials_userpwline">
                    <>
                      <TextField
                        className={user ? "username_input_logged_in" : "username_input_logged_out"}
                        disabled={isAuthenticated}
                        value={user ? user.username : ''}
                        error={typeof authFailed === 'string'}
                        name="username"
                        placeholder="Uporabniško ime"
                        // autoComplete="current-username"
                        fullWidth
                        onChange={(e) => {
                          setAuthFailed(false);
                        }}
                        helperText={authFailed || null}
                      />
                      <button
                        type="button"
                        onClick={() => handleLogoutClick(true)}
                        className="cancel_button"
                        style={{
                          display: 'inline-flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginRight: 0,
                        }}
                      >
                        ODJAVA
                      </button>
                    </>
                  </div>
                </div>
                {user && !loadingCfg && configError && (
                  <p>Prišlo je do napake pri generiranju nastavitev. Poskusite osvežiti stran.</p>
                )}
                {user && loadingCfg ? (
                  <LinearProgress style={{ width: '100%', marginTop: '10px' }} color={'secondary'} />
                ) : (
                  !loadingCfg &&
                  !configError &&
                  config &&
                  sttStatus &&
                  user && (
                    <>
                      <div className="transcription_wrapper">
                        <div className="transcription_title_line">
                          <div>Transkripcija</div>
                          {/*<Switch
             color="primary"
             checked={isTranscript}
             onChange={handleTranscriptionSwitch("checked")}
             value="checked"
             inputProps={{ "aria-label": "primary checkbox" }}
           />*/}
                        </div>

                        <div className="options_wrapper">
                            {activeQuota && activeQuota.chunk.secondsLimit && (
                              <div className="checkbox_wrapper">
                                <div>Omejitev uporabe</div>
                                <div className='consumption_container'>
                                  <Tooltip id='consumption_tooltip' placement='top' title={`Dosegli ste ${(activeQuota.chunk.secondsConsumed / activeQuota.chunk.secondsLimit * 100).toFixed(2)}% vaše ${translateChunkKey(activeQuota.key)} zakupljene količine, oz. ${transformConsumedAndLimitToReadable(activeQuota.chunk.secondsConsumed, activeQuota.chunk.secondsLimit)}.`}>
                                    <div className='consumption_wrapper'>
                                      <ConsumptionBar value={activeQuota.chunk.secondsConsumed} maxValue={activeQuota.chunk.secondsLimit} />
                                    </div>
                                  </Tooltip>
                                  <button className='consumption_button_wrapper' onClick={showConsumptionModal}>
                                    <img src="info.svg" alt="Info svg" className='consumption_button_image' />
                                  </button>
                                </div>
                              </div>
                            ) || activeQuota && (
                              
                              <div className="checkbox_wrapper">
                                <div>Trenutna poraba</div>
                                <div className='consumption_container'>
                                  <Tooltip placement='top' title="Poraba v obračunskem obdobju">
                                    <p className='consumption_without_limit_paragraph' style={{marginRight: 24}}>{transformConsumedToReadable(activeQuota.key, activeQuota.chunk.secondsConsumed)}</p>
                                  </Tooltip>
                                </div>
                              </div>
                            )}
                          {isAdmin && (
                            <TextField
                              style={{
                                opacity: config.stt.framework.isAllowed ? 1 : 0.4,
                                fontSize: '14px',
                              }}
                              disabled={!config.stt.framework.isAllowed}
                              name="framework"
                              select
                              value={config.stt.framework.value ? config.stt.framework.value : ''}
                              onChange={handleFrameworkChange}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start" className="input_adornment">
                                    Ogrodje
                                  </InputAdornment>
                                ),
                              }}
                            >
                              {sttStatus.frameworks.map((framework) => {
                                return (
                                  <MenuItem
                                    disabled={!framework.isAllowed}
                                    key={framework.code}
                                    value={framework.code}
                                  >
                                    {framework.code}
                                  </MenuItem>
                                );
                              })}
                            </TextField>
                          )}
                          <TextField
                            style={{
                              opacity: config.stt.language.isAllowed ? 1 : 0.4,
                              fontSize: '14px',
                            }}
                            disabled={!config.stt.language.isAllowed}
                            name="transcriptionLanguage"
                            select
                            value={config.stt.language.value}
                            onChange={handleLanguageChange}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start" className="input_adornment">
                                  Jezik
                                </InputAdornment>
                              ),
                            }}
                          >
                            {localLanguages &&
                              localLanguages.map((language) => {
                                return (
                                  <MenuItem
                                    disabled={!language.isAllowed}
                                    key={language.code}
                                    value={language.code}
                                    style={
                                      !language.isAllowed
                                        ? {
                                            opacity: '0.4',
                                            pointerEvents: 'none',
                                            touchAction: 'none',
                                          }
                                        : {}
                                    }
                                  >
                                    {language.code}
                                  </MenuItem>
                                );
                              })}
                          </TextField>

                          <TextField
                            style={{
                              opacity: config.stt.domain.isAllowed ? 1 : 0.4,
                              fontSize: '14px',
                            }}
                            disabled={!config.stt.domain.isAllowed || config.stt.language.value === ''}
                            // inputRef={register}
                            name="transcriptionDomain"
                            select
                            value={config.stt.domain.value}
                            onChange={handleDomainChange}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start" className="input_adornment">
                                  <p>Domena</p>
                                </InputAdornment>
                              ),
                            }}
                          >
                            {localDomains &&
                              localDomains.map((domain) => (
                                <MenuItem
                                  disabled={!domain.isAllowed}
                                  key={domain.code}
                                  value={domain.code}
                                  style={
                                    !domain.isAllowed
                                      ? {
                                          opacity: '0.4',
                                          pointerEvents: 'none',
                                          touchAction: 'none',
                                        }
                                      : {zIndex: 99999999999999}
                                  }
                                >
                                  {domain.code}
                                </MenuItem>
                              ))}
                          </TextField>

                          <TextField
                            style={{
                              fontSize: '14px',
                            }}
                            disabled={!config.stt.model.isAllowed || config.stt.domain.value === ''}
                            // inputRef={register}
                            name="transcriptionModelVersion"
                            select
                            value={config.stt.model.value}
                            onChange={handleModelChange}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start" className="input_adornment">
                                  <p>Verzija</p>
                                </InputAdornment>
                              )
                            }}
                          >
                            {localModels &&
                              localModels.map((option) => (
                                <MenuItem
                                  key={option.code}
                                  value={option.code}
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                    ...((!option.isAvailable || !option.isAllowed || option.isUpdating) && {
                                      opacity: '0.4',
                                      pointerEvents: 'none',
                                      touchAction: 'none',
                                    }),
                                  }}
                                >
                                  <span>{option.code}</span>
                                  {/* <span
                                style={{
                                  width: 8,
                                  height: 8,
                                  borderRadius: option.realtime ? '50%' : 0,
                                  backgroundColor: option.realtime ? '#ff6666' : '#31a0ff',
                                  marginLeft: 6,
                                }}
                              /> */}
                                </MenuItem>
                              ))}
                          </TextField>

                          {config.nlp.enableDenormalization.isAllowed && (
                            <SettingOption
                              title="Denormalizacija"
                              disabled={false}
                              checked={config.nlp.enableDenormalization.value}
                              onChange={handleDenormalizationToggle}
                             />
                          )}

                          {config.nlp.enableTruecasing.isAllowed && (
                            <SettingOption
                              title="Samodejno postavljanje velikih začetnic"
                              disabled={false}
                              checked={config.nlp.enableTruecasing.value}
                              onChange={handleTruecasingToggle}
                             />
                          )}

                          {config.nlp.enableTruecasing.isAllowed && (
                            <SettingOption
                              title="Samodejno postavljanje ločil"
                              disabled={false}
                              checked={config.nlp.punctuation.enabled.value}
                              onChange={handlePunctuationToggle}
                             />
                          )}

                          {diarizationSupport.current && config.stt.enableDiarization.isAllowed && (
                             <SettingOption
                               title="Samodejno ločevanje govorcev"
                               disabled={false}
                               checked={config.stt.enableDiarization.value}
                               onChange={handleDiarizationToggle}
                             />
                           )}

                          {dictationCommandsSupport.current && config.stt.enableDictatedPunctuations.isAllowed && (
                             <SettingOption
                               title="Narekovana ločila"
                               disabled={false}
                               checked={config.stt.enableDictatedPunctuations.value}
                               onChange={handleDictatedPonctuationsToggle}
                             />
                           )}

                          {dictationCommandsSupport.current && config.stt.enableDictatedCommands.isAllowed && (
                             <SettingOption
                               title="Narekovani ukazi"
                               disabled={false}
                               checked={config.stt.enableDictatedCommands.value}
                               onChange={handleDictatedCommandsToggle}
                             />
                           )}


                          {isModelUpdatable.current && !isModelUpdating.current && (
                            <div
                              className="checkbox_wrapper">
                              <div>Slovar</div>
                              <UpdatingDictSection
                                isModelUpdatable={isModelUpdatable.current}
                                isModelUpdating={isModelUpdating.current}
                                version={config.stt.model.value}
                                domain={config.stt.domain.value}
                                language={config.stt.language.value}
                              />
                            </div>
                          )}

                          {isRealTime && config.stt.enableInterimTranscripts.isAllowed && (
                             <SettingOption
                               title="Prikazuj delne transkripte"
                               titleStyle={{ fontStyle: 'italic' }}
                               disabled={false}
                               checked={config.stt.enableInterimTranscripts.value}
                               onChange={handleInterimsToggle}
                             />
                           )}

                          {config.nlp.punctuation.enableRealFinals.isAllowed && (
                             <SettingOption
                               title="Razbijaj končne transkripte na povedi"
                               titleStyle={{ fontStyle: 'italic' }}
                               disabled={false}
                               checked={config.nlp.punctuation.enableRealFinals.value}
                               onChange={handleRealFinalsToggle}
                             />
                           )}

                          {isAdmin && (
                            <div className="checkbox_wrapper_last">
                              <div style={{ fontStyle: 'italic' }}>Prikaži lastnosti modela</div>
                              <button onClick={onModelInfoClick}>
                                <img src="info.svg" alt="Info svg" style={{ width: 24, height: 24 }} />
                              </button>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="transcription_wrapper">
                        <div className="transcription_title_line">
                          <div>Zgodovina</div>
                        </div>
                        <div className="options_wrapper">
                          <NumberOfRowsPerPageSetter />
                          <div
                            className="checkbox_wrapper_last"
                            // style={{
                            //   opacity: !currentModelAvailable || !allowModelUpdating ? 0.4 : 1,
                            //   pointerEvents: !currentModelAvailable || !allowModelUpdating ? 'none' : 'all',
                            // }}
                          >
                            <div>Privzete labele</div>
                            <AddLabelsButton />
                          </div>
                        </div>
                      </div>
                    </>
                  )
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {config && modelInfoModalVisible && (
        <div
          onClick={closeModelInfoModal}
          style={{
            zIndex: 35,
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#0000001A',
          }}
        >
          <div
            onClick={preventOnClick}
            style={{
              paddingLeft: 39,
              paddingTop: 33,
              paddingBottom: 37,
              paddingRight: 44,
              width: '40%',
              backgroundColor: '#F2F2F2',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {(modelMetadata && (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: 59,
                  }}
                >
                  <p style={{ margin: 0, fontSize: 36, fontWeight: 300, fontFamily: 'Roboto' }}>
                    {`${config.stt.language.value}:${config.stt.domain.value}:${config.stt.model.value}`}
                  </p>
                  <button type="button" onClick={closeModelInfoModal}>
                    <ClearIcon />
                  </button>
                </div>
                <p
                  style={{
                    fontSize: 12,
                    fontWeight: 700,
                    fontFamily: 'Roboto',
                    marginBottom: 22,
                    marginTop: 0,
                  }}
                >
                  Lastnosti modela
                </p>
                <pre className="settings_show_scrollbar">{JSON.stringify(modelMetadata, null, 2)}</pre>
              </>
            )) || (
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <p>Podatki za model trenutno niso na voljo</p>
                <button type="button" onClick={closeModelInfoModal}>
                  <ClearIcon />
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

interface RegenStatusData {
  startedAt?: string;
  statusCode: DictStatesEnum;
  enableModelUpdating?: boolean;
}

const UpdatingDictSection = ({
  domain,
  version,
  language,
  isModelUpdating,
  isModelUpdatable,
}: DictProps) => {
  const history = useHistory();

  const [state, setState] = useState<DictStatesEnum>(DictStatesEnum.NOT_RUNNING);

  const handleRegenerateClick = async () => {
    try {
      const data = await startModelRegeneration({ language, domain, modelVersion: version });
      if (data.status === 204) {
        setState(DictStatesEnum.RUNNING);
        //trigger refetch?
      }
      // console.log('Regenerate data:', data);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="dict_commands_wrapper">
      {state === DictStatesEnum.FAILED && <FailedSyncingIcon />}
      {isModelUpdatable && !isModelUpdating && (
        <>
          {/*<span className="num_of_new_words">{numOfNewWords}</span>*/}
          <button
            className="update_dict_button sync"
            type="button"
            disabled={false}
            onClick={handleRegenerateClick}
            style={{ cursor: 'pointer', opacity: 1, pointerEvents: 'all' }}
          >
            <DictDesynchedIcon />
          </button>
        </>
      )}
      {!isModelUpdatable && !isModelUpdating && <DictSynchedIcon />}
      {isModelUpdating && <AnimatedInProgress style={{ margin: '0 4px' }} />}
      {config.environment !== 'demo' && (
        <button
          onClick={() => history.push('/dictionary')}
          className="update_dict_button"
          disabled={!(isModelUpdatable && !isModelUpdating)}
          type="button"
          style={{ cursor: 'pointer', opacity: 1, pointerEvents: isModelUpdatable && !isModelUpdating ? 'all' : 'none' }}>
          <DictEditIcon />
        </button>
      )}
    </div>
  );
};

const AnimatedInProgress = (props: any) => {
  const [iconNum, setIconNum] = useState<number>(0);

  useInterval(
    () => {
      setIconNum((prev) => {
        if (prev === 3) {
          return 0;
        }

        return prev + 1;
      });
    },
    600,
    []
  );

  return (
    <div style={props.style || {}} className="dict_aimation_wrapper">
      {iconNum === 0 && <DictSyncingIcon0 />}
      {iconNum === 1 && <DictSyncingIcon1 />}
      {iconNum === 2 && <DictSyncingIcon2 />}
      {iconNum === 3 && <DictSyncingIcon3 />}
    </div>
  );
};

const rows = {
  min: 5,
  max: 35,
};

const NumberOfRowsPerPageSetter = () => {
  const pageSize = useSelector((state: IStore) => state.dashboardPagination.pageSize);
  const userName = useSelector((state: IStore) => state.user?.username);
  const dispatch = useDispatch();

  const handleChange = (e: any) => {
    const { value } = e.target;
    const num = parseInt(value, 10);
    if (num > rows.max || num < rows.min) return;
    dispatch(setDashboardPagination({ pageSize: num }));
    userName &&
      localStorage.setItem(
        `editor-${userName}-page-size`,
        JSON.stringify({
          pageSize: num,
        })
      );
  };

  const handleBlur = () => {
    if (!pageSize || pageSize === 0 || isNaN(pageSize) || pageSize > rows.max || pageSize < rows.min) {
      dispatch(setDashboardPagination({ pageSize: rows.min }));
      userName &&
        localStorage.setItem(
          `editor-${userName}-page-size`,
          JSON.stringify({
            pageSize: rows.min,
          })
        );
    }
  };

  return (
    <TextField
      style={{
        fontSize: '14px',
      }}
      className="type_number"
      type="number"
      value={pageSize}
      onChange={handleChange}
      name="historyNumberOfPages"
      InputProps={{
        inputProps: { min: rows.min, max: rows.max },
        onBlur: handleBlur,
        startAdornment: (
          <InputAdornment position="start" className="input_adornment">
            Število zapisov na stran
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SettingsDrawer;

interface ISettingOption {
  title: string,
  disabled: boolean,
  checked: boolean,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  titleStyle?: CSSProperties;
}
const SettingOption = ({
  title,
  disabled,
  checked,
  onChange,
  titleStyle
}: ISettingOption) => {
  
    return (
      <div
        className="checkbox_wrapper"
        style={{
          opacity: disabled ? 0.4 : 1,
          pointerEvents: disabled ? 'none' : 'all',
        }}
      >
        <div style={titleStyle}>{title}</div>
        <Checkbox
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          color="primary"
        />
      </div>
    )
}