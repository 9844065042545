import { apm } from '@elastic/apm-rum';

import {
  AnimButtonStatesEnum,
  EditorModeEnums,
  ILoadContentToEditor,
  IRefectTokens,
  ISnack,
  IStore,
  IUser,
  IWsAction,
  IAddColumnMenu,
  IUpdateEditorState,
  ISessionLabel,
  ISortObject,
  IPageable,
  DashboardRefreshState,
  IHeader,
  IHeaderWidth,
  IDictionary,
  IHomeFlowStates,
  IConsumtionModal,
  SpeakersModalTypeEnum,
  ISpeakersSettingsModal,
} from '../store/IStore';
import { IAction } from '../actions/IActions';
import { combineReducers } from 'redux';
import {
  SET_ACTIVE_WORD_ID,
  SET_SESSION_NAME,
  SET_IS_SESSION_DISCARDED,
  SET_AUTH_OK_GET_CONFIG,
  SET_CLICKED_TIME,
  SET_CURRENTTIME,
  SET_DO_DICTATION,
  SET_EDITOR_MODE,
  SET_FONT_SIZE,
  SET_HEADER_IS_EXTENDED,
  SET_IS_PLAYING,
  SET_IS_TRANSCRIPT,
  SET_MAKE_BLOCKS,
  SET_SAVE_TO_DISK,
  SET_SESSION_ID,
  SET_SHOW_CONFIDENCE,
  SET_TB_FORMAT,
  SET_URL_NAME,
  SET_USER,
  SET_YOUTUBE_LINK,
  SET_ANIM_BUTTON_STATE,
  SET_LAST_ORIGINAL_TEXT,
  SET_CONFIG,
  SET_STT_STATUS,
  SET_SNACK,
  SET_WS_ACTION,
  SET_ENTITIES_KEYS_TO_MERGE,
  SET_LOAD_CONTENT_TO_EDITOR,
  SET_VALID_REDIRECT,
  SET_UPLOADED_FILE_DURATION,
  SET_AUDIO_INFO,
  SET_REFETCH_TOKENS,
  SET_FONTS_LOADED,
  SET_DASHBOARD_FILTERS,
  SET_ADD_COLUMN,
  SET_MANUALLY_UPDATE_EDITOR_STATE,
  SET_DASHBOARD_EXPENDED_ROW,
  SET_SESSION_LABELS,
  SET_DASHBOARD_REFRESH,
  SET_DASHBOARD_SORT,
  SET_DASHBOARD_PAGINATION,
  SET_DASHBOARD_HEADERS,
  SET_DASHBOARD_TABLE_WIDTH,
  SET_DASHBOARD_TABLE_REFRESH,
  SET_DASHBOARD_HEADER_WIDTHS,
  SET_DASHBOARD_HEADER_FILTERS,
  RESET_DASHBOARD_HEADERS,
  SET_SPEAKERS_MODAL,
  SET_IS_AUDIO_PLAYING,
  SET_DASHBAORD_FILTER_COMMANDS,
  SET_DICTIONARY,
  SET_DICT_WORD_PRONAUN,
  SET_DICTIONARY_WORDS,
  SET_ADD_PRONAUN,
  SET_HOME_FLOW_STATE,
  SET_ACTIVE_QUOTA,
  SET_CONSUMPTION_MODAL,
  SET_DIARIZATION,
  SET_SPEAKER_UPDATED,
  SET_ROW_OPENING,
  SET_SPEAKER_SETTINGS_MODAL
} from '../actions/actions';
import { ITbFormat } from '../../components/Editor/IEditor';
import { IConfig, ISttStatus } from '../../components/Home/ISettings';
import { IAppliedFilter } from '../../components/DashboardHeader/ISearch';
import { IAddToDictWord } from '../../api/DictionaryService';
import { IActiveUserQuotaChunk } from '../../api/UserService';
import { config as envConfig } from '../../config';

const initialState: IStore = {
  refetchTokens: { refetchCount: 0, refetchTime: null },
  doDictation: false,
  isTranscript: true,
  isPlaying: false,
  currentTime: 0,
  audioInfo: { url: '', loadNew: false },
  tbFormat: {},
  homeFlowStates: {
    uploadFlowInProgress: false,
    liveFlowInProgress: false,
  },
  dictionary: {
    phones: null,
    contextString: {
      contextLeft: 'homeopatsko zdravilo',
      contextRight: 'se uporablja',
      mainPhrase: 'amoxiclav forte extra',
    },
    pageSize: 5,
    caseSensitive: false,
    searchBy: 'word',
    searchedString: '',
    words: [],
    autoSave: null,
  },
  isAudioPlaying: false,
  speakersModal: {
    showModal: false,
    speaker: null,
    blockKey: null,
    modalType: SpeakersModalTypeEnum.NORMAL,
    editorSelection: null,
  },
  fontsLoaded: false,
  youtubeLink: null,
  showConfidence: false,
  editorMode: null,
  clickedTime: null,
  fontSize: '22px',
  headerIsExtended: true,
  loadContentToEditor: {
    recFinishedStartLoadingNewEditorState: false,
    recStartedLoadTextFromEditor: false,
  },
  manuallyUpdateEditorState: {
    update: false,
  },
  sessionName: '',
  isSessionDiscarded: false,
  activeWordId: '',
  uploadedFileDuration: null,
  makeBlocks: false,
  saveToDisk: false,
  user: null,
  authOkGetConfig: false,
  config: null,
  sttStatus: null,
  sessionId: '',
  snack: {
    open: false,
    message: '',
    variant: 'success',
  },
  urlName: 'unknown',
  animButtonStates: AnimButtonStatesEnum.NORMAL,
  lastOriginalText: '',
  wsAction: IWsAction.OPEN,
  entitiesKeysToMerge: [],
  validRedirect: false,
  dashboardFilters: [],
  addColumnsMenu: { anchor: null, id: 'string' },
  dashboardExpendedRow: null,
  sessionsLabels: [],
  dashboardRefresh: DashboardRefreshState.NONE,
  dashboardSort: {
    columnName: null,
    type: null,
  },
  dashboardPagination: {
    offset: 0,
    pageNumber: 0,
    pageSize: 25,
    paged: false,
    totalElements: 0,
  },
  dashboardFilterCommands: {
    showDeletedSessions: true,
  },
  dashboardHeader: null,
  dashboardTableWidth: null,
  dashboardTableRefresh: DashboardRefreshState.NONE,
  dashboardHeaderWidths: null,
  activeQuota: null,
  consumptionModal: {
    visible: false,
    template: undefined,
  },
  diarization: false,
  speakerUpdated: false,
  rowOpening: false,
  speakerSettingsModal: {
    visible: false,
    numberOfSpeakers: null,
    minNumberOfSpeakers: null,
    maxNumberOfSpeakers: null,
    retranscription: false,
  }
};

// const wsConnected = (state: boolean | null = initialState.wsConnected, action: IAction) => {
//   switch (action.type) {
//     case SET_WS_CONNECTED:
//       return action.wsConnected;
//     default:
//       return state;
//   }
// };

const homeFlowStates = (state: IHomeFlowStates = initialState.homeFlowStates, action: IAction) => {
  switch (action.type) {
    case SET_HOME_FLOW_STATE:
      return { ...state, ...action.homeFlowStates };
    default:
      return state;
  }
};

const dashboardFilterCommands = (state: any = initialState.dashboardFilterCommands, action: IAction) => {
  switch (action.type) {
    case SET_DASHBAORD_FILTER_COMMANDS:
      return { ...state, ...action.dashboardFilterCommands };
    default:
      return state;
  }
};

const dictionary = (state: IDictionary = initialState.dictionary, action: IAction) => {
  switch (action.type) {
    case SET_DICTIONARY:
      return { ...state, ...action.dictionary };

    case SET_DICTIONARY_WORDS:
      return { ...state, words: action.newWords };

    case SET_ADD_PRONAUN:
      let wordToSave: null | IAddToDictWord = null;
      const nw = state.words?.map((w, i) => {
        if (i === action.addPronaunData?.wordIndex) {
          wordToSave = { ...w, pronunciations: [...w.pronunciations, action.addPronaunData.pronaun] };
          return wordToSave;
        }
        return w;
      });
      return { ...state, words: nw, autoSave: wordToSave };

    case SET_DICT_WORD_PRONAUN:
      let wordToSaveDE: null | IAddToDictWord = null;
      const wordI = action.pronaunData?.wordIndex || 0;
      const pI = action.pronaunData?.pronaunIndex || 0;
      const newP = action.pronaunData?.pronaun;

      const newWords = state.words?.map((w, i) => {
        if (i === wordI) {
          const nwS = {
            ...w,
            pronunciations: newP
              ? w.pronunciations.map((p, j) => {
                  if (j === pI) {
                    return newP;
                  }
                  return p;
                })
              : w.pronunciations.filter((p, k) => k !== pI),
          };
          wordToSaveDE = { ...nwS };
          return nwS;
        }
        return w;
      });

      return { ...state, words: newWords, autoSave: wordToSaveDE };
    default:
      return state;
  }
};

const isAudioPlaying = (state: boolean = initialState.isAudioPlaying, action: IAction) => {
  switch (action.type) {
    case SET_IS_AUDIO_PLAYING:
      return action.isAudioPlaying;
    default:
      return state;
  }
};

const validRedirect = (state: boolean | null = initialState.validRedirect, action: IAction) => {
  switch (action.type) {
    case SET_VALID_REDIRECT:
      return action.validRedirect;
    default:
      return state;
  }
};

const refetchTokens = (state: IRefectTokens = initialState.refetchTokens, action: IAction) => {
  switch (action.type) {
    case SET_REFETCH_TOKENS:
      // return { ...state, ...action.audioInfo };
      return { refetchCount: state.refetchCount + 1, refetchTime: action.refetchTokens };
    default:
      return state;
  }
};

const uploadedFileDuration = (state: number | null = initialState.uploadedFileDuration, action: IAction) => {
  switch (action.type) {
    case SET_UPLOADED_FILE_DURATION:
      return action.uploadedFileDuration;
    default:
      return state;
  }
};

const entitiesKeysToMerge = (state: string[] = initialState.entitiesKeysToMerge, action: IAction) => {
  switch (action.type) {
    case SET_ENTITIES_KEYS_TO_MERGE:
      return action.entitiesKeysToMerge;
    default:
      return state;
  }
};

const wsAction = (state: IWsAction = initialState.wsAction, action: IAction) => {
  switch (action.type) {
    case SET_WS_ACTION:
      return action.wsAction;
    default:
      return state;
  }
};

const manuallyUpdateEditorState = (
  state: IUpdateEditorState = initialState.manuallyUpdateEditorState,
  action: IAction
) => {
  switch (action.type) {
    case SET_MANUALLY_UPDATE_EDITOR_STATE:
      return action.manuallyUpdateEditorState;
    default:
      return state;
  }
};

const doDictation = (state: boolean = initialState.doDictation, action: IAction) => {
  switch (action.type) {
    case SET_DO_DICTATION:
      return action.doDictation;
    default:
      return state;
  }
};

// const wsProps = (state: IWsProps = initialState.wsProps, action: IAction) => {
//   switch (action.type) {
//     case SET_WS_PROPS:
//       return action.wsProps;
//     default:
//       return state;
//   }
// };

// const receivedMessage = (state: IReceived | null = initialState.receivedMessage, action: IAction) => {
//   switch (action.type) {
//     case SET_RECEIVED_MESSAGE:
//       return action.receivedMessage;
//     default:
//       return state;
//   }
// };

// const messageToSend = (state: ISend | null = initialState.messageToSend, action: IAction) => {
//   switch (action.type) {
//     case SET_MESSAGE_TO_SEND:
//       return action.messageToSend;
//     default:
//       return state;
//   }
// };

const youtubeLink = (state: string | null = initialState.youtubeLink, action: IAction) => {
  switch (action.type) {
    case SET_YOUTUBE_LINK:
      return action.youtubeLink;
    default:
      return state;
  }
};

const fontsLoaded = (state: boolean | null = initialState.fontsLoaded, action: IAction) => {
  switch (action.type) {
    case SET_FONTS_LOADED:
      return action.fontsLoaded;
    default:
      return state;
  }
};

// const variant = (state: string = initialState.variant, action: IAction) => {
//   switch (action.type) {
//     case SET_VARIANT:
//       return action.variant;
//     default:
//       return state;
//   }
// };

const tbFormat = (state: ITbFormat = initialState.tbFormat, action: IAction) => {
  switch (action.type) {
    case SET_TB_FORMAT:
      return action.tbFormat;
    default:
      return state;
  }
};

const audioInfo = (state: any = initialState.audioInfo, action: IAction) => {
  switch (action.type) {
    case SET_AUDIO_INFO:
      return { ...state, ...action.audioInfo };
    default:
      return state;
  }
};

const speakersModal = (state: any = initialState.speakersModal, action: IAction) => {
  switch (action.type) {
    case SET_SPEAKERS_MODAL:
      return { ...state, ...action.speakersModal };
    default:
      return state;
  }
};

const currentTime = (state: number = initialState.currentTime, action: IAction) => {
  switch (action.type) {
    case SET_CURRENTTIME:
      return action.currentTime;
    default:
      return state;
  }
};

const isTranscript = (state: boolean = initialState.isTranscript, action: IAction) => {
  switch (action.type) {
    case SET_IS_TRANSCRIPT:
      return action.isTranscript;
    default:
      return state;
  }
};

const isPlaying = (state: boolean = initialState.isPlaying, action: IAction) => {
  switch (action.type) {
    case SET_IS_PLAYING:
      return action.isPlaying;
    default:
      return state;
  }
};

const showConfidence = (state: boolean = initialState.showConfidence, action: IAction) => {
  switch (action.type) {
    case SET_SHOW_CONFIDENCE:
      return action.showConfidence;
    default:
      return state;
  }
};

const editorMode = (state: EditorModeEnums | null = initialState.editorMode, action: IAction) => {
  switch (action.type) {
    case SET_EDITOR_MODE:
      return action.editorMode;
    default:
      return state;
  }
};

const clickedTime = (state: number | null = initialState.clickedTime, action: IAction) => {
  switch (action.type) {
    case SET_CLICKED_TIME:
      return action.clickedTime;
    default:
      return state;
  }
};

const fontSize = (state: string = initialState.fontSize, action: IAction) => {
  switch (action.type) {
    case SET_FONT_SIZE:
      return action.fontSize;
    default:
      return state;
  }
};

const headerIsExtended = (state: boolean = initialState.headerIsExtended, action: IAction) => {
  switch (action.type) {
    case SET_HEADER_IS_EXTENDED:
      return action.headerIsExtended;
    default:
      return state;
  }
};

const sessionName = (state: string | null = initialState.sessionName, action: IAction) => {
  switch (action.type) {
    case SET_SESSION_NAME:
      return action.sessionName;
    default:
      return state;
  }
};

const isSessionDiscarded = (state: boolean = initialState.isSessionDiscarded, action: IAction) => {
  switch (action.type) {
    case SET_IS_SESSION_DISCARDED:
      return action.isSessionDiscarded;
    default:
      return state;
  }
};

const activeWordId = (state: string = initialState.activeWordId, action: IAction) => {
  switch (action.type) {
    case SET_ACTIVE_WORD_ID:
      return action.activeWordId;
    default:
      return state;
  }
};

const makeBlocks = (state: boolean = initialState.makeBlocks, action: IAction) => {
  switch (action.type) {
    case SET_MAKE_BLOCKS:
      return action.makeBlocks;
    default:
      return state;
  }
};

const saveToDisk = (state: boolean = initialState.saveToDisk, action: IAction) => {
  switch (action.type) {
    case SET_SAVE_TO_DISK:
      return action.saveToDisk;
    default:
      return state;
  }
};

const user = (state: IUser | null = initialState.user, action: IAction) => {
  switch (action.type) {
    case SET_USER:
      // Should set to empty string for non-existing or logged-out user
      // see: https://github.com/elastic/apm-agent-rum-js/issues/1085
      apm.setUserContext({ id: '', username: action.user?.username || '', email: '' });
      return action.user;
    default:
      return state;
  }
};

const authOkGetConfig = (state: boolean = initialState.authOkGetConfig, action: IAction) => {
  switch (action.type) {
    case SET_AUTH_OK_GET_CONFIG:
      return action.authOkGetConfig;
    default:
      return state;
  }
};

const sessionId = (state: string = initialState.sessionId, action: IAction) => {
  switch (action.type) {
    case SET_SESSION_ID:
      return action.sessionId;
    default:
      return state;
  }
};

// const dModelId = (state: number = initialState.dModelId, action: IAction) => {
//   switch (action.type) {
//     case SET_D_MODEL_ID:
//       return action.dModelId;
//     default:
//       return state;
//   }
// };

// const isLiveMode = (state: boolean = initialState.isLiveMode, action: IAction) => {
//   switch (action.type) {
//     case SET_IS_LIVE_MODE:
//       return action.isLiveMode;
//     default:
//       return state;
//   }
// };

const urlName = (state: string = initialState.urlName, action: IAction) => {
  switch (action.type) {
    case SET_URL_NAME:
      return action.urlName;
    default:
      return state;
  }
};

// const recordingLive = (state: string = initialState.recordingLive, action: IAction) => {
//   switch (action.type) {
//     case SET_RECORDING_LIVE:
//       return action.recordingLive;
//     default:
//       return state;
//   }
// };

const config = (state: IConfig | null = initialState.config, action: IAction) => {
  switch (action.type) {
    case SET_CONFIG:
      return action.config;
    default:
      return state;
  }
};

const sttStatus = (state: ISttStatus | null = initialState.sttStatus, action: IAction) => {
  switch (action.type) {
    case SET_STT_STATUS:
      return action.sttStatus;
    default:
      return state;
  }
};

const animButtonStates = (state: AnimButtonStatesEnum = initialState.animButtonStates, action: IAction) => {
  switch (action.type) {
    case SET_ANIM_BUTTON_STATE:
      return action.animButtonState;
    default:
      return state;
  }
};

// const checkWebSocketState = (state: boolean = initialState.checkWebSocketState, action: IAction) => {
//   switch (action.type) {
//     case SET_CHECK_WEB_SOCKET_STATE:
//       return action.checkWebSocketState;
//     default:
//       return state;
//   }
// };

const lastOriginalText = (state: string = initialState.lastOriginalText, action: IAction) => {
  switch (action.type) {
    case SET_LAST_ORIGINAL_TEXT:
      return action.lastOriginalText;
    default:
      return state;
  }
};

const snack = (state: ISnack = initialState.snack, action: IAction) => {
  switch (action.type) {
    case SET_SNACK:
      return action.snack;
    default:
      return state;
  }
};

const loadContentToEditor = (
  state: ILoadContentToEditor = initialState.loadContentToEditor,
  action: IAction
) => {
  switch (action.type) {
    case SET_LOAD_CONTENT_TO_EDITOR:
      return { ...state, ...action.loadContentToEditor };
    default:
      return state;
  }
};

const dashboardFilters = (state: IAppliedFilter[] = initialState.dashboardFilters, action: IAction) => {
  switch (action.type) {
    case SET_DASHBOARD_FILTERS:
      return action.dashboardFilters;
    default:
      return state;
  }
};

const addColumnsMenu = (state: IAddColumnMenu = initialState.addColumnsMenu, action: IAction) => {
  switch (action.type) {
    case SET_ADD_COLUMN:
      return action.addColumnsMenu;
    default:
      return state;
  }
};

const dashboardExpendedRow = (state: number | null = initialState.dashboardExpendedRow, action: IAction) => {
  switch (action.type) {
    case SET_DASHBOARD_EXPENDED_ROW:
      return action.dashboardExpendedRow;
    default:
      return state;
  }
};

const sessionsLabels = (state: ISessionLabel[] = initialState.sessionsLabels, action: IAction) => {
  switch (action.type) {
    case SET_SESSION_LABELS:
      return action.sessionsLabels;
    default:
      return state;
  }
};

const dashboardRefresh = (state: DashboardRefreshState = initialState.dashboardRefresh, action: IAction) => {
  switch (action.type) {
    case SET_DASHBOARD_REFRESH:
      return action.dashboardRefresh;
    default:
      return state;
  }
};

const dashboardSort = (state: ISortObject = initialState.dashboardSort, action: IAction) => {
  switch (action.type) {
    case SET_DASHBOARD_SORT:
      return action.dashboardSort;
    default:
      return state;
  }
};

const dashboardPagination = (state: IPageable = initialState.dashboardPagination, action: IAction) => {
  switch (action.type) {
    case SET_DASHBOARD_PAGINATION:
      return { ...state, ...action.dashboardPagination };
    default:
      return state;
  }
};

const dashboardHeader = (state: IHeader | null = initialState.dashboardHeader, action: IAction) => {
  switch (action.type) {
    case RESET_DASHBOARD_HEADERS:
      return action.dashboardHeader;

    case SET_DASHBOARD_HEADERS:
      return { ...state, ...action.dashboardHeader };

    case SET_DASHBOARD_HEADER_FILTERS:
      const newState = JSON.parse(JSON.stringify(state)) as IHeader;
      const appliedAtributes = (action.dashboardHeaderFilters || []).map((filter) => filter[0].field);

      Object.keys(newState).forEach((headerKey) => {
        newState[headerKey].filtered = appliedAtributes.includes(headerKey);
      });

      return newState;
    default:
      return state;
  }
};

const dashboardHeaderWidths = (
  state: IHeaderWidth | null = initialState.dashboardHeaderWidths,
  action: IAction
) => {
  switch (action.type) {
    case SET_DASHBOARD_HEADER_WIDTHS:
      return { ...state, ...action.dashboardHeaderWidths };
    default:
      return state;
  }
};

const dashboardTableWidth = (state: number | null = initialState.dashboardTableWidth, action: IAction) => {
  switch (action.type) {
    case SET_DASHBOARD_TABLE_WIDTH:
      return action.dashboardTableWidth;
    default:
      return state;
  }
};

const dashboardTableRefresh = (
  state: DashboardRefreshState = initialState.dashboardTableRefresh,
  action: IAction
) => {
  switch (action.type) {
    case SET_DASHBOARD_TABLE_REFRESH:
      return action.dashboardTableRefresh;
    default:
      return state;
  }
};

const activeQuota = (
  state: IActiveUserQuotaChunk | null = initialState.activeQuota,
  action: IAction
) => {
  switch (action.type) {
    case SET_ACTIVE_QUOTA:
      return action.activeQuota;
    default:
      return state;
  }
};

const consumptionModal = (
  state: IConsumtionModal = initialState.consumptionModal,
  action: IAction
) => {
  switch (action.type) {
    case SET_CONSUMPTION_MODAL:
      return action.consumptionModal;
    default:
      return state;
  }
};

const diarization = (
  state: boolean = initialState.diarization,
  action: IAction
) => {
  switch (action.type) {
    case SET_DIARIZATION:
      return action.diarization;
    default:
      return state;
  }
};

const speakerUpdated = (
  state: boolean = initialState.speakerUpdated,
  action: IAction
) => {
  switch (action.type) {
    case SET_SPEAKER_UPDATED:
      return action.speakerUpdated;
    default:
      return state;
  }
};

const rowOpening = (
  state: boolean = initialState.rowOpening,
  action: IAction
) => {
  switch (action.type) {
    case SET_ROW_OPENING:
      return action.rowOpening;
    default:
      return state;
  }
};

const speakerSettingsModal = (state: ISpeakersSettingsModal = initialState.speakerSettingsModal, action: IAction) => {
  switch (action.type) {
    case SET_SPEAKER_SETTINGS_MODAL:
      return {...state, ...action.speakerSettingsModal};
    default:
      return state;
  }
}

const app = combineReducers({
  //   variant,
  isTranscript,
  isPlaying,
  entitiesKeysToMerge,
  currentTime,
  audioInfo,
  tbFormat,
  wsAction,
  youtubeLink,
  loadContentToEditor,
  showConfidence,
  editorMode,
  clickedTime,
  fontSize,
  headerIsExtended,
  uploadedFileDuration,
  //   messageToSend,
  //   receivedMessage,
  //   wsProps,
  doDictation,
  speakersModal,
  //   wsConnected,
  sessionName,
  isSessionDiscarded,
  activeWordId,
  makeBlocks,
  saveToDisk,
  validRedirect,
  //   dModelId,
  user,
  snack,
  authOkGetConfig,
  sessionId,
  manuallyUpdateEditorState,
  urlName,
  //   isLiveMode,
  //   recordingLive,
  animButtonStates,
  //   checkWebSocketState,
  lastOriginalText,
  fontsLoaded,
  refetchTokens,
  isAudioPlaying,
  dashboardFilterCommands,
  config,
  sttStatus,
  dashboardFilters,
  addColumnsMenu,
  dashboardExpendedRow,
  sessionsLabels,
  dashboardRefresh,
  dashboardSort,
  dashboardPagination,
  dashboardHeader,
  dashboardTableWidth,
  dashboardTableRefresh,
  dashboardHeaderWidths,
  dictionary,
  homeFlowStates,
  activeQuota,
  consumptionModal,
  diarization,
  speakerUpdated,
  rowOpening,
  speakerSettingsModal
});

export default app;
