import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './redux/store';
import { WsProvider } from './providers/WsProvider';
import { QueryClient, QueryClientProvider } from 'react-query';

import './index.css';
import { SnackbarProvider } from 'notistack';
import { ClearIcon } from './components/Icons/ClearIcon';

// Elastic APM Real User Monitoring
import { init as initApm } from '@elastic/apm-rum';
import { config } from './config';
import WarnMessage from './components/CustomNotistack/WarnMessage';
import {KeycloakAuthProvider} from './providers/KeycloakAuthProvider';
import useKeycloak from './hooks/useKeycloak';
import NewtonsCradleLoader from './components/Loaders/NewtonsCradle';

const { appName, monitoringApmServerUrl, monitoringEnabled, environment, appVersion, backendApiUrl } = config;

declare module 'notistack' {
  interface VariantOverrides {
    warn: true;
  }
}


initApm({
  // The Elastic APM service name is used to differentiate data from each of your services (allowed characters: a-z, A-Z, 0-9, -, _, and space)
  serviceName: appName,
  // The URL used to make requests to the APM Server.
  serverUrl: monitoringApmServerUrl,
  // The version of the app.
  serviceVersion: appVersion,
  // The environment where the service being monitored is deployed, e.g. "production", "development", "test", etc.
  environment: environment,
  // Specifies if the agent should be active or not. If active, the agent will send APM transactions and track errors.
  active: monitoringEnabled,
  // This effectively tells the RUM APM agent to add the distributed tracing HTTP header ('traceparent') to requests
  // made to 'backendApiUrl'.
  // https://www.elastic.co/guide/en/apm/agent/rum-js/master/distributed-tracing-guide.html#enable-cors
  distributedTracingOrigins: [backendApiUrl],
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const notistackRef: any = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef?.current.closeSnackbar(key);
};

const RedirectLoader = () => {
  return (
    <div className='loader_container'>
      <NewtonsCradleLoader />
    </div>
  )
}
const Wrapper = () => {
  const {
    isAuthenticated
  } = useKeycloak();

  if (isAuthenticated) {
    return (
      <SnackbarProvider
        maxSnack={5}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        // @ts-ignore
        ref={notistackRef}
        action={(key) => (
          <button onClick={onClickDismiss(key)}>
            <ClearIcon fill="white" style={{ color: 'white' }} />
          </button>
        )}
        autoHideDuration={5000}
        dense={true}
        Components={{
          warn: WarnMessage
        }}
      >

        <QueryClientProvider client={queryClient}>
          <WsProvider>
            <App />
          </WsProvider>
        </QueryClientProvider>
      </SnackbarProvider>
    )
  }

  return <RedirectLoader />
}

ReactDOM.render(
  <Provider store={store}>
      <KeycloakAuthProvider>
        <Wrapper />
      </KeycloakAuthProvider>
    </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
