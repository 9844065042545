import { Paragraph, PatchType, TextRun, patchDocument } from 'docx';
import { saveAs } from 'file-saver';
import Subtitles from '../components/Libraries/Subtitles';
import { convertToRaw, EditorState, RawDraftContentState } from 'draft-js';
import { convertEditorStateToTranscript } from '../shared/DataConverters';
import { useSelector } from 'react-redux';
import { IStore } from '../redux/store/IStore';
import useEditor from './useEditor';
import { useClipboard } from './useClipboardCopy';
import { useCallback, useEffect, useRef } from 'react';
import { covertEditorStateToHTML } from '../components/Editor/Editor';
import { useSnackbar } from 'notistack';

// DOCX formatting:
// Run formatting
// bold, italics, smallCaps, allCaps, strike, doubleStrike, subScript, superScript: Set the formatting property to true
// underline({type="single", color=null}): Set the underline style and color
// emphasisMark({type="dot"}): Set the emphasis mark style
// color(color): Set the text color, using 6 hex characters for RRGGBB (no leading #)
// size(halfPts): Set the font size, measured in half-points
// font(name) or font({ascii, cs, eastAsia, hAnsi, hint}): Set the run's font
// style(name): Apply a named run style
// characterSpacing(value): Set the character spacing adjustment (in TWIPs)

// Paragraph formatting
// heading1, heading2, heading3, heading4, heading5, title: apply the appropriate style to the paragraph
// left, center, right, justified: set the paragraph's alignment
// thematicBreak, pageBreak: Insert a thick rule or a page break beneath the paragraph
// leftTabStop(position): Add a left tab stop (measured in TWIPs from the left)
// maxRightTabStop: Add a right tab stop at the far right
// bullet: Use the default bullet style
// setNumbering(numbering, indentLevel): Use a custom numbering format for the paragraph
// style(name): Apply a named paragraph style
// indent(start, hanging=0): Set the paragraph's indent level (in TWIPs)
// spacing({before=0, after=0, line=0}): Set the line and before/after on the paragraph. Before/after is measured in TWIPs, line is measured in 240ths of a line
// Paragraph styles have all the run formatting methods, except style(), and left(), center(), right(), justified(), thematicBreak(), leftTabStop(position), maxRightTabStop(), indent(start, hanging=0), and spacing({before=0, after=0, line=0}) methods.

const createDocxAndDownload = async (editorState: EditorState, fileName: string) => {
  if (!editorState) return;
  const editorPs = convertToRaw(editorState.getCurrentContent());
  let prevSpeaker: any = null;

  let docxParagraphs: Paragraph[] = [];
  
  editorPs.blocks.forEach((block) => {
    const speaker = block.data?.speaker;

    const prevBlockIsSame =
      speaker && prevSpeaker !== null && prevSpeaker && prevSpeaker.id
        ? prevSpeaker.id === speaker.id
        : false;

    const styles = block.inlineStyleRanges;

    const speakerParagraph =
      !prevBlockIsSame && speaker
        ? new Paragraph({
            children: [
              new TextRun({
                size: 20,
                text: speaker?.name || 'NEZNANI GOVOREC',
                bold: true,
                underline: {},
              }),
            ],
          })
        : null;

    if (styles.length === 0 || !styles) {
      speakerParagraph && docxParagraphs.push(new Paragraph({}));
      speakerParagraph && docxParagraphs.push(speakerParagraph);

      docxParagraphs.push(
        new Paragraph({
          children: [
            new TextRun({
              text: block.text,
            }),
          ],
        })
      );
    }
    let wordsArr: any[] = [];

    block.text.split('').forEach((char, i) => {
      let currChar: any = { text: char, styles: '' };

      styles.forEach((style, j) => {
        const start = style.offset;
        const end = style.offset + style.length;

        if (start <= i && end > i && style.length) {
          currChar = { ...currChar, styles: currChar.styles + style.style.charAt(0) };
        } else {
        }
      });

      const ind = wordsArr.length - 1;

      if (wordsArr.length && wordsArr[ind] && wordsArr[ind].styles === currChar.styles) {
        wordsArr[ind].text += currChar.text;
      } else {
        wordsArr.push(currChar);
      }
    });

    if (styles && styles.length) {
      speakerParagraph && docxParagraphs.push(new Paragraph({}));
      speakerParagraph && docxParagraphs.push(speakerParagraph);
      
      docxParagraphs.push(
        new Paragraph({
          children: wordsArr.map((word) => {
            const s = word.styles;
            return new TextRun({
              text: word.text,
              bold: s.includes('B'),
              italics: s.includes('I'),
              underline: s.includes('U') ? {} : undefined,
            });
          }),
        })
      );
    }
    prevSpeaker = speaker;
  });

  const content = await (await fetch("./Normal.docx")).blob()
  const data = await patchDocument(content, {
    patches: {
      content: {
        type: PatchType.DOCUMENT,
        children: docxParagraphs
      }
    },
  });

  const blob = new Blob([data])

  const blobUrl = URL.createObjectURL(blob);

  // Create a download link
  const downloadLink = document.createElement('a');
  downloadLink.href = blobUrl;
  downloadLink.download = fileName


  downloadLink.style.display = 'none';
  document.body.appendChild(downloadLink);

  downloadLink.click();

  document.body.removeChild(downloadLink);
};

export default function useDownload() {
  const { editorState } = useEditor();
  const audioUrl = useSelector((state: IStore) => state.audioInfo.url);
  const sessionName = useSelector<IStore, string>((state) => state.sessionName);
  const { enqueueSnackbar } = useSnackbar();

  const handleDownloadDocx = useCallback(async () => {
    //await generateFile();
    if (!editorState) return;

    const filename = sessionName ? sessionName + '.docx' : 'transkript.docx';
    await createDocxAndDownload(editorState, filename);
  }, [editorState, sessionName]);

  const { copy, copied } = useClipboard({
    copiedTimeout: 1750,
  });
  const htmlCopied = useRef(false);

  useEffect(() => {
    if (copied) {
      enqueueSnackbar(
        htmlCopied.current
          ? 'Html uspešno kopiran v odložišče.'
          : 'Besedilo je bilo uspešno kopirano v odložišče.',
        { variant: 'success' }
      );
      htmlCopied.current = false;
    }
  }, [copied]);

  const handleCopyTextToClipBoard = useCallback(
    (isHtml?: boolean) => {
      if (isHtml) {
        const html = covertEditorStateToHTML(editorState);

        copy(html);
        htmlCopied.current = true;
        return;
      }

      let textToCopy = '';

      const raw = convertToRaw(editorState.getCurrentContent());
      let prevSpeaker: any = null;

      raw.blocks.forEach((b) => {
        const speaker = b.data?.speaker;

        const prevBlockIsSame =
          speaker && prevSpeaker !== null && prevSpeaker && prevSpeaker.id
            ? prevSpeaker.id === speaker.id
            : false;

        textToCopy = `${textToCopy}${
          speaker && !prevBlockIsSame ? `\n${speaker?.name}\n${b.text}` : b.text
        }\n`;
        prevSpeaker = speaker;
      });

      copy(textToCopy);
    },
    [editorState]
  );

  const handleDownloadSrt = useCallback(() => {
    if (!editorState) return;
    const { transcript } = convertEditorStateToTranscript(editorState, { toLiveWordData: true });
    Subtitles(transcript, sessionName);
  }, [editorState, sessionName]);

  const handleDownloadRaw = useCallback(() => {
    if (!editorState) return;
    const tbFormat: RawDraftContentState = convertToRaw(editorState.getCurrentContent());
    const rawFormatBlob = new Blob([JSON.stringify(tbFormat)], {
      type: 'application/json',
    });
    saveAs(rawFormatBlob, sessionName + '.raw');
  }, [editorState, sessionName]);

  const handleDownloadTb = useCallback(async () => {
    if (!editorState) return;

    // const { liveWordTranscript } = convertEditorStateToTranscript(editorState, { toLiveWordData: true });

    let tbFormatBlob = new Blob(
      [JSON.stringify({ rawContentState: convertToRaw(editorState.getCurrentContent()) })],
      {
        type: 'application/json',
      }
    );
    // TO-DO: Let use insert the audio name
    const fileName = sessionName ? sessionName : 'live_recording';

    saveAs(tbFormatBlob, fileName + '.tb');
  }, [sessionName, editorState]);

  const handleDownloadAudio = useCallback(async () => {
    const fileName = sessionName ? sessionName : 'Untitled session';
    saveAs(audioUrl, `${fileName}.wav`);
  }, [sessionName]);

  return {
    handleDownloadDocx,
    handleDownloadSrt,
    handleDownloadTb,
    handleDownloadRaw,
    handleCopyTextToClipBoard,
    handleDownloadAudio,
  };
}
