import axios from 'axios';
import { setConfig, setDashboardFilters, setRefetchTokens, setSttStatus, setUser } from '../redux/actions/actions';
import store from '../redux/store';
import { removeTokens } from './AuthenticationService';
import { enqueueSnackbar } from 'notistack'
import { keycloak } from '../providers/KeycloakAuthProvider';

const instance = axios.create();

instance.interceptors.request.use(
  async (config) => {

    const user = store.getState().user
    if (!user || !user.isEditorUser) {
      return config;
    }
    
    const isTokenReq = typeof config.data === 'string' && config.data.includes('grant_type');

    try {
      await keycloak.updateToken(5)
      const user = store.getState().user
      if (user) {
        store.dispatch(setUser({
          ...user,
          accessToken: keycloak.token,
          idToken: keycloak.idToken
        }))
      } else {
        throw new Error("Undefined user in request interceptor")
      }    
    } catch (error) {
      console.log(error)
    }
    

    if (keycloak.token && keycloak.token !== '' && !isTokenReq) {
      config.headers.Authorization = `Bearer ${keycloak.token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    // removeToken();
    // console.log('ERROR FROM AXIOS:', error, error.response);
    if (error.response && error.response.status === 401) {
      /*removeTokens();
      store.dispatch(setRefetchTokens(null));
      store.dispatch(setUser(null));
      store.dispatch(setConfig(null));
      store.dispatch(setSttStatus(null));
      store.dispatch(setDashboardFilters([]));*/
    } else if (error.response && error.response.status === 500) {
      enqueueSnackbar(`Prišlo je do napake na zalednem delu, prosimo kontaktirajte tehnično podporo.`, {
        variant: 'error',
      });
      
      if (error.response.id) {
        enqueueSnackbar(`Koda napake: ${error.response.id}`, {
          variant: 'error',
        });
      }
    }

    const customEvent = new CustomEvent('axios-error', { detail: error.response });
    // console.log(error.response);
    document.dispatchEvent(customEvent);

    return Promise.reject(error);
  }
);

export default instance;
