import React, { useCallback, useEffect } from 'react';
import Main from './components/Main/Main';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import useCheckTokenAndSetUser from './hooks/useCheckTokenAndSetUser';
import './styles/css/App.css';
import '@draft-js-plugins/mention/lib/plugin.css';
import useLoadFonts from './hooks/useLoadFonts';
import useLocalStorage from './hooks/useLocalStorage';
import useAxiosErrorListener from './hooks/useAxiosErrorListener';
import Modal from './components/Home/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { ConsumtionModalTemplate, IStore } from './redux/store/IStore';
import { setActiveQuota, setConsumptionModal } from './redux/actions/actions';
import { getActiveQuota, getUserQuota, transformConsumedAndLimitToReadable, transformLimitToReadable, translateChunkKey } from './api/UserService';
import './app.css'
import useInterval from './hooks/useInterval';
import useKeycloak from './hooks/useKeycloak';
import { isUpdating } from './shared/helpers';

const App: React.FC = () => {
  useLoadFonts();
  useLocalStorage();
  useAxiosErrorListener();

  const dispatch = useDispatch();
  const {
    visible,
    template
  } = useSelector((store: IStore) => store.consumptionModal);
  const activeQuota = useSelector((store: IStore) => store.activeQuota);
  const user = useSelector((store: IStore) => store.user);
  const {
    isAuthenticated
  } = useKeycloak();
  const sttStatus = useSelector((state: IStore) => state.sttStatus);
  const config = useSelector((state: IStore) => state.config);

  const hideConsumptionModal = () => dispatch(setConsumptionModal({
    visible: false,
    template: undefined,
  }))

  const fetchQuota = useCallback(async () => {
    if (isAuthenticated) {
      try {
        const quota = await getUserQuota()
        dispatch(setActiveQuota(getActiveQuota(quota.data)))
      } catch (error) {
        console.log(error)
      }
    }
  }, [isAuthenticated])

  useEffect(() => {
    fetchQuota()
  }, [isAuthenticated])

  useInterval(() => {
    fetchQuota();
  }, 15000, [isAuthenticated])

  //const footerVisible = !(config && isUpdating(sttStatus, config)) && user && !user.isEditorUser
  return (

    <ErrorBoundary>
      <Main />
      <Modal visible={visible} closeModal={hideConsumptionModal} title="Omejitev uporabe">
        {activeQuota && activeQuota.chunk.secondsLimit && (
          <div className='consumption-modal-content-container'>
            {template === ConsumtionModalTemplate.LIMIT && (
              <>
                <p className='consumption-modal-bold-text'>Storitev je (začasno) onemogočena.</p>
                <p className='consumption-modal-regular-text'>{`Presegli ste dogovorjeno količino časa uporabe storitve (${transformLimitToReadable(activeQuota.key, activeQuota.chunk.secondsLimit ? activeQuota.chunk.secondsLimit : 0)}).`}</p>
                <p className='consumption-modal-regular-text'>Za dodatne informacije pišite na <a className='consumption-modal-email-text' href="mailto:info@vitasis.si">info@vitasis.si</a>.</p>
              </>
            ) || template === ConsumtionModalTemplate.UPLOAD_LIMIT && (
              <>
                <p className='consumption-modal-bold-text'>{`Dosegli ste ${(activeQuota.chunk.secondsConsumed / activeQuota.chunk.secondsLimit * 100).toFixed(2)}% vaše ${translateChunkKey(activeQuota.key)} zakupljene količine, oz. ${transformConsumedAndLimitToReadable(activeQuota.chunk.secondsConsumed, activeQuota.chunk.secondsLimit)}.`}</p>
                <p className='consumption-modal-regular-text'>Z izbrano datoteko bi zakupljene količine presegli, zato storitev ni mogoča.</p>
                <p className='consumption-modal-regular-text'>Izberite krajšo datoteko oz. za dodatne informacije pišite na <a className='consumption-modal-email-text' href="mailto:info@vitasis.si">info@vitasis.si</a>.</p>
              </>
            ) || (
                <>
                  <p className='consumption-modal-bold-text'>{`Dosegli ste ${(activeQuota.chunk.secondsConsumed / activeQuota.chunk.secondsLimit * 100).toFixed(2)}% vaše ${translateChunkKey(activeQuota.key)} zakupljene količine, oz. ${transformConsumedAndLimitToReadable(activeQuota.chunk.secondsConsumed, activeQuota.chunk.secondsLimit)}.`}</p>
                  <p className='consumption-modal-regular-text'>Ob presegu dogovorjene količine bo storitev (začasno) onemogočena.</p>
                  <p className='consumption-modal-regular-text'>Za dodatne informacije pišite na <a className='consumption-modal-email-text' href="mailto:info@vitasis.si">info@vitasis.si</a>.</p>
                </>
              )}
          </div>
        ) || <></>}
      </Modal>
      {user && !user.isEditorUser && (
        <div className='not-editor-user-blur-container'>
          <div className='not-editor-user-blur-content-container'>
            <p className='not-editor-user-text'>Za uporabo storitev nimate ustreznih dovoljenj. Preverite pri skrbniku sistema.</p>
          </div>
          <div className="footer_info not-editor-user-footer">© <b>Vitasis</b> & <b>Laboratorij za podatkovne tehnologije</b>, UL-FRI</div>
        </div>
      )}
    </ErrorBoundary>
  );
};

export default App;
