import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import useKeycloak from '../../hooks/useKeycloak';

interface Props {
  children: JSX.Element;
}

const AuthGuard = ({ children }: Props) => {
  const { isAuthenticated } = useKeycloak();

  useEffect(() => {
    console.log("AUTH GUARD")
    console.log(isAuthenticated)
  }, [])

  if (!isAuthenticated) {
    return <Redirect to="/" />;
  }

  return <>{children}</>;
};

export default AuthGuard;
