import Keycloak from 'keycloak-js';
import React, { ReactNode, createContext, useEffect, useState } from 'react';
import { JWTDecoded } from '../components/Dashboard/IDashboard';
import jwtDecode from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../redux/actions/actions';
import { IStore, UserRoleEnums } from '../redux/store/IStore';
import { config } from '../config';

export interface IKeycloakAuthProps {
    children: ReactNode;
}
interface IKeycloakAuthContext {
    isAuthenticated: boolean,
}
const KeycloakAuthContext = createContext<IKeycloakAuthContext>({
    isAuthenticated: false
});

const { 
    keycloakUrl,
    publicUrl,
    keycloakClientId,
    keycloakAuthReam,
    keycloakIdpAlias
} = config;

export const keycloak = new Keycloak({
    url: `${keycloakUrl}/auth`,
    realm: keycloakAuthReam,
    clientId: keycloakClientId,
});

export const KeycloakAuthProvider: React.FC<IKeycloakAuthProps> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
    const dispatch = useDispatch();
    const user = useSelector((store: IStore) => store.user)
    const consumptionModal = useSelector((state: IStore) => state.consumptionModal);
    
    useEffect(() => {
        if (keycloak.token && user) {
            dispatch(setUser({
                username: user.username,
                isAuthenticated: user.isAuthenticated,
                accessToken: keycloak.token,
                refreshToken: keycloak.refreshToken,
                userRoles: user.userRoles,
                isEditorUser: user.isEditorUser,
                idToken: keycloak.idToken,
              }))
        }
    }, [keycloak.token])
    useEffect(() => {
        const wrapper = async () => {
            
            if (keycloak.authenticated || consumptionModal.visible) {
                setIsAuthenticated(true)
            } else {
                try {
                    const response = await keycloak.init({
                        checkLoginIframe: false,
                        //onLoad: 'login-required'
                        redirectUri: publicUrl,
                        
                    })

                    if (!response) {
                        const queryParamAdmin = (new URL(document.location.href)).searchParams.get("isLocalUser")
                        const isAdmin = queryParamAdmin && queryParamAdmin === "true"

                        keycloak.login({ redirectUri: publicUrl, idpHint: isAdmin ? undefined : keycloakIdpAlias })
                        return;
                    }

                    if (keycloak.token) {
                        const decodedTokenData: JWTDecoded = jwtDecode(keycloak.token);
                        dispatch(
                            setUser({
                                idToken: keycloak.idToken,
                                username: decodedTokenData.preferred_username,
                                accessToken: keycloak.token,
                                refreshToken: keycloak.refreshToken,
                                isAuthenticated: true,
                                userRoles: decodedTokenData.realm_access.roles,
                                isEditorUser: decodedTokenData.realm_access.roles.includes(UserRoleEnums.EDITOR_USER) && decodedTokenData.realm_access.roles.includes(UserRoleEnums.API_USER),
                            })
                        );
                        setIsAuthenticated(true)
                    } else {
                        setIsAuthenticated(false)
                    }

                    
                } catch (error) {
                    console.log(error)
                }
            }
        }

        wrapper();
    }, [])

    return <KeycloakAuthContext.Provider value={{ isAuthenticated }}>{children}</KeycloakAuthContext.Provider>;
};

export default KeycloakAuthContext;